import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { replaceDot } from "../Helpers";
import '../../assets/css/components/Account/CardList.css';

const CardList = (props) => {
    const {t} = useTranslation();
    const {data, match, history, setReadCardModal} = props;

    return(
        <div className="card-list flex flex-direction-column bg-white box-shadow">
            <button className={`all-cards bg-grey-light cl-black${match.params.cid === undefined ? " selected" : ""}`} onClick={(e)=>{
                history.push({
                    pathname:"/account/" + data?.id
                })
            }}>{parse(t("CardList:all-cards"))}</button>
            <button className='add-card bg-yellow cl-black bold' onClick={(e) => {
                setReadCardModal(true);
            }}>{parse(t("CardList:add-card"))}</button>

            <div className="cards flex flex-direction-column">
                <div className={`card main${data?.bloqueetat ? " blocked" : ""}${Number(match.params.cid) === 0 ? " selected" : ""}`} data-value="0" onClick={(e) => {
                        history.push({
                            pathname:"/account/" + data?.id + '/0'
                        });
                    }}>
                    <div className='name'>
                        <div className='label'>{data?.nom}</div>
                        <div className='limit normal'>{parse(t("CardList:no-limit"))}</div>
                    </div>
                </div>
                {data?.braceletannexes !== null && data?.braceletannexes?.length > 0 && data?.braceletannexes?.map((obj, i) => {
                    if (obj !== undefined && !obj.remplaced) {
                        return(
                            <div key={i} className={`card ${obj.bloqueetat ? " blocked" : ""}${Number(match.params.cid) === Number(obj.id) ? " selected" : ""}`} onClick={(e) => {
                                history.push({
                                    pathname:"/account/" + data?.id + "/" + obj.id
                                });
                            }}>
                                <div className='name'>
                                    <div className='label'>{obj.textelogiciel}</div>
                                    {obj.limitemontant === 0 && obj.limitetype === "aucune" && !obj.bloqueetat &&
                                        <div className='limit normal'>{parse(t("CardList:no-limit"))}</div>
                                    }

                                    {obj.limitemontant > 0 && obj.limitetype !== "aucune" && !obj.bloqueetat &&
                                        <div className='limit normal'>
                                            {parse(t("CardList:limit", {number:replaceDot(obj.limitemontant), type:parse(t("CardList:" + obj.limitetype)), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}<br/>
                                            {Number(obj.disponible) >= 0 &&
                                            <i>{parse(t("CardList:available", {number:replaceDot(obj.disponible), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</i>
                                            }
                                        </div>
                                    }

                                    {obj.bloqueetat &&
                                        <div className='limit normal'>{parse(t("CardList:blocked-card"))}</div>
                                    }
                                    
                                </div>
                            </div>
                        )
                    } else {
                        return (null);
                    }
                    
                })}
                
            </div>
        </div>
    );
}

export default CardList;