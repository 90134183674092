import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import bgAddCard from '../../../assets/images/bg-add-card.png';
import '../../../assets/css/components/Account/Modals/AddCardModal.css';
import { replaceComma } from '../../Helpers';

const AddCardModal = (props) => {
    const {t} = useTranslation();
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const { data, uuid, history, setData, setAddCardModal, setReadCardModal } = props;
    const [amountError, setAmountError] = useState("");
    const [connectionError, setConnectionError] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [isDoingAction, setIsDoingAction] = useState(false);
    const [formData, setFormData] = useState({
        idcompte:"",
        uuidcompte:"",
        associeuuid:"",
        associetexte:"",
        associelimite: "aucune",
        associemontant: "0"
    });

    const addAuxCard = async() => {
        if (token !== "" && url !== "" && uuid !== null && data !== null && formData !== null) {

            let tmpObj = formData;

            tmpObj.token = token;
            tmpObj.uuidcompte = data.braceletprincipal;
            tmpObj.idcompte = data.id;
            tmpObj.associeuuid = uuid;

            await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-ajouteannexe.awp", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    "Accept": "*/*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Connection": "keep-alive"
                },
                body: JSON.stringify(tmpObj)
            })
            .then(res => res.json())
            .then(
            (result) => {
         
                if (result.resultat === "ok") {
                    if (result.reponse === "ajout") {
                        setData(result.data);
                        setAddCardModal(false);
                        setReadCardModal(false);
                        
                        let bracelet = "";
                        Object.keys(result.data.braceletannexes).forEach((i) => {
                            let obj = result.data.braceletannexes[i];
                            
                            if (obj.bracelet === uuid) {
                                bracelet = "/" + obj.id;
                            }
                        });
                        
                        history.push({
                            pathname: "/account/" + data.id + bracelet,
                        });

                    }
                }

                if (result.resultat === "erreur") {
                    if (result.reponse === "Identification compte") {
                        setConnectionError(parse(t("AddCardModal:id-uuid-mismatch")));
                    }

                    if (result.reponse === "Pb TOKEN ou problème limite non reconnue") {
                        setConnectionError(parse(t("AddCardModal:token-or-limit-unrecognized")));
                    }
                    
                    if (result.reponse === "Données manquantes compte") {
                        setConnectionError(parse(t("AddCardModal:missing-data")));
                    }

                    if (result.reponse === "format non respecte") {
                        setConnectionError(parse(t("AddCardModal:wrong-data")));
                    }
                }

                setIsDoingAction(false);
            },
            (error) => {
                setConnectionError(parse(error));
            });
        }
    };

    const handleSubmit = (e) => {
          e.preventDefault();

          if (!isDoingAction) {
            setIsDoingAction(true);

            //-> Si le type de limite pas mis à jour
            if (formData.associelimite !== "aucune") {
    
                if (formData.associemontant === "" || formData.associemontant === "0") {
                    setAmountError(parse(t("AddCardModal:enter-amount")));
                    setIsDoingAction(false);
                } else {
                    setFormData(prevState => ({
                        ...prevState, 
                        associemontant:replaceComma(formData.associemontant)
                    }));
                    setAmountError("");
                    addAuxCard();
                }
                
            } else {
                setAmountError("");
                addAuxCard();
            }
        }
          
      }
  
      const onEnterPress = (e) => {
        if (e.key === "Enter") {
          handleSubmit(e)
        }
      }

    return(
    <div className={`fixed flex flex-direction-column align-items-center justify-content-center add-card-modal on`}>
        <div className='relative modal-container bg-white box-shadow flex flex-direction-column justify-content-center align-items-center'>
            
   
            <img className="desktop-all bg-add-card absolute" src={bgAddCard} alt={parse(t("AddCardModal:add-card"))} width="131" height="460" />

            <div className='content full-width flex flex-direction-column justify-content-center align-items-center full-height'>
                <div className="modal-title bolder-x">{parse(t("AddCardModal:add-card"))}</div>
 
                {uuid !== null &&
                    <div className="card-uuid text-center bolder uppercase">{parse(t("AddCardModal:uuid"))}<br/><span className="bg-turquoise cl-white bolder-x">{uuid}</span><br/>
                    </div>
                }

                <form onSubmit={handleSubmit}>
                 
                    <div className="form-group full-width bg-grey-light limit text-left">

                        <div className='input card-name'>
                            <p>{parse(t("AddCardModal:osmopay-name"))}
                                <label htmlFor="associetexte" className="group-item flex flex-direction-column text-left normal align-items-start relative">{parse(t("AddCardModal:placeholder"))} 

                                    <input type="text" maxLength="80" className="form-control bolder" name="associetexte" id="associetexte" onChange={e => {
                                        setFormData(prevState => ({...prevState, associetexte:e.target.value}));
                                    }} onKeyDown={onEnterPress} />
                                </label>
                            </p>
                        </div>
                    
                        <p>{parse(t("AddCardModal:limit"))}</p>
                        <div className="labels flex flex-direction-row flex-wrap">
                        <label htmlFor="aucune" className="group-item flex flex-direction-row align-items-center relative">
                            <div className="label-styled bg-grey-light relative">
                                <input type="checkbox" id="aucune" name="aucune" value="aucune" checked={formData.associelimite === "aucune"} onChange={e => {
                                setAmountError("");
                                setConnectionError("");
                                setFormData(prevState => ({
                                    ...prevState, 
                                    associelimite:e.target.value,
                                    associemontant:"0"
                                }));

                                setIsDisabled(true)
                                }} />
                                <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="aucune"></label>
                            </div>
                            <span>{parse(t("AddCardModal:no-limit"))}</span>
                        </label>

                        <label htmlFor="jour" className="group-item flex flex-direction-row align-items-center relative">
                            <div className="label-styled bg-grey-light relative">
                            <input type="checkbox" id="jour" name="jour" value="jour" checked={formData.associelimite === "jour"} onChange={e => { 
                                setAmountError("");
                                setConnectionError("");
                                
                                setFormData(prevState => ({
                                    ...prevState, 
                                    associelimite:e.target.value,
                                    associemontant:""
                                }));
                                
                                setIsDisabled(false);
                            }} />

                                <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="jour"></label>
                            </div>
                            <span>{parse(t("AddCardModal:daily-limit"))}</span>
                        </label>

                        <label htmlFor="sejour" className="group-item flex flex-direction-row align-items-center relative">
                            <div className="label-styled bg-grey-light relative">
                            <input type="checkbox" id="sejour" name="sejour" value="sejour" checked={formData.associelimite === "sejour"} onChange={e => {    
                                setIsDisabled(false);
                                setAmountError("");
                                setConnectionError("");

                                setFormData(prevState => ({
                                    ...prevState, 
                                    associelimite:e.target.value,
                                    associemontant:""
                                }));
                                
                            }} />

                                <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="sejour"></label>
                            </div>
                            <span>{parse(t("AddCardModal:stay-limit"))}</span>
                        </label>
                        </div>

                        <div className="input">
                            <p>{parse(t("AddCardModal:limit-amount"))}
                                <label htmlFor="focus" className="group-item flex flex-direction-row text-left normal align-items-start relative uppercase">
                                    <input min="0" step="0.01" type="number" 
                                    value={formData.associemontant} 
                                    onKeyDown={onEnterPress}
                                    onChange={e => {

                                        let filteredVal = e.target.value;

                                        setFormData(prevState => ({
                                            ...prevState, 
                                            associemontant:e.target.value
                                        }));

                                        
                                        setConnectionError("");

                                        if (filteredVal !== "0" && filteredVal !== "") {
                                            setAmountError("");
                                        } else {
                                            setAmountError(parse(t("AddCardModal:enter-amount")));
                                        }
                                    }}

                                    onFocus={e => {
                                        setIsFocused(true)
                                    }}

                                    onBlur={e => {
                                        setIsFocused(false)
                                    }}

                                    disabled={isDisabled} className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")} id="focus" /><span className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")}>{data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</span>
                                    <em className="cl-error1 absolute">{amountError}</em>
                                </label>
                            </p>
                        </div>
                    </div>

                    <div className="buttons relative flex flex-direction-row flex-wrap align-items-center justify-content-center">
                        <button className='cancel' onClick={()=> {
                                setAddCardModal(false);
                            }}>{parse(t("AddCardModal:cancel"))}
                        </button>
                        <button className='save'>{parse(t("AddCardModal:save"))}</button>
                    </div>
            
                </form>
                
                <div className='error-container relative'>
                    {connectionError !== "" &&
                    <div className='relative cl-error1 bg-error2 flex align-items-center justify-content-center bolder text-transform-none text-center'>{connectionError}</div>
                    }
                </div>

                <button className='close absolute' onClick={()=> {
                 setAddCardModal(false);
                }}>{parse(t("AddCardModal:close"))}
                </button>
            </div>
            
            

            
  
        </div>
    </div>
    );
}

export default AddCardModal;