import React, { Component } from 'react';
import FormErrors from './FormErrors';
import FormSuccess from './FormSuccess';
import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { handleUserInput, errorClass, onFieldKeyDown, clearLocalStorage, toggleRecaptcha } from './Helpers';
import LanguageSwitcher from './LanguageSwitcher';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import bgLogin from '../assets/images/login.png';
import '../assets/css/components/Login.css';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordShown: false,
      formErrors: {email: '', password: '', error: ''},
      emailValid: false,
      passValid: false,
      formValid: false,
      isDoingAction:false
    }

    this.togglePassword = this.togglePassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }

  onVerify = async () => {
    const { t } = this.props;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
  
    if (!executeRecaptcha) {
      // console.log('Recaptcha has not been loaded');
      return;
    }

    if (this.state.isDoingAction === false) {

      this.setState({
        isDoingAction : true
      });
      
      if(this.state.formValid) {
  
        if (this.state.email !== "" && this.state.password !== "") {
  
          let submitTimeLimit = Number(localStorage.getItem('r-submitTimeLimit'));
          let trialLimit = Number(localStorage.getItem('r-trialLimit'));
          
          if (submitTimeLimit !== 0) {
            let currentDate = new Date();
  
            if (currentDate.getTime() > submitTimeLimit) {
              const token = await executeRecaptcha('Login');

              if(token !== null) {
                localStorage.removeItem('r-submitTimeLimit')
                localStorage.removeItem('r-trialLimit');
                this.queryApi(this.state.email, this.state.password)
              }
            } else {
              let endDate = new Date(submitTimeLimit);
              let minutesLeft = parseInt(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 60) % 60);
  
              this.setState({
                formErrors: {error:t("FormErrors:login-limit", {
                  minutesLeft : minutesLeft+1
                })}
              });

              let $this = this;
              setTimeout(function() {
                $this.setState({
                  isDoingAction:false
                });
              }, 2000);
            }
          } else {

            if (trialLimit >= 2) {
              let submitTimeLimit = Number(localStorage.getItem('r-submitTimeLimit'));
              let currentDate = new Date();
              let endDate;

              if (submitTimeLimit === 0) {
                let minutesToAdd = 2;
                let futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);
                localStorage.setItem('r-submitTimeLimit', futureDate.getTime());
                endDate = new Date(futureDate.getTime());
              } else {
                endDate = new Date(Number(localStorage.getItem('r-submitTimeLimit')));
              }
              
              let minutesLeft = parseInt(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 60) % 60);
  
              this.setState({
                formErrors: {error:t("FormErrors:login-limit", {
                  minutesLeft : minutesLeft+1
                })}
              });

              let $this = this;
              setTimeout(function() {
                $this.setState({
                  isDoingAction:false
                });
              }, 2000);
    
            } else {
              localStorage.setItem('r-trialLimit', trialLimit+1)
              
              const token = await executeRecaptcha('Login');

              if(token !== null) {
                this.queryApi(this.state.email, this.state.password)
              }
            }
            
          }
  
        } else {
          this.setState({
            formErrors: {error: t("FormErrors:empty-fields")}
          });

          let $this = this;
          setTimeout(function() {
            $this.setState({
              isDoingAction:false
            });
          }, 2000);
        }
      } else {
        this.setState({
          formErrors: {error: t("FormErrors:empty-fields")}
        });

        let $this = this;
        setTimeout(function() {
          $this.setState({
            isDoingAction:false
          });
        }, 2000);
      }
    }
  }

  validateField(fieldName, value) {
    
    const {t} = this.props;
    let emailValid = this.state.emailValid;
    let passValid = this.state.passValid;
    let fieldValidationErrors = this.state.formErrors;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = (emailValid || value === "") ? '' : t('FormErrors:invalid-email');
      break;
      case 'password':
        passValid = value !== "";
        fieldValidationErrors.password = value !== "" ? '' : t('FormErrors:empty-password');
      break;

      case 'error':
        fieldValidationErrors.error = value
      break;
      default:
      break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      formSuccess: false,
      emailValid: emailValid,
      passValid: passValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passValid
    });
  }

  getToken = async (email, password, url, logo) => {

    const {t, history} = this.props;
  
    if (email !== "" && password !== "" && url !== "") {
      await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-token.awp", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          "Accept": "*/*",
          "Accept-Encoding": "gzip, deflate, br",
          "Connection": "keep-alive"
        },
        body: JSON.stringify({
          "login": email,
          "mdp": password,
        }),
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.resultat === "ok") {
            
            localStorage.setItem('r-loggedIn', true)
            localStorage.setItem('r-token', result.data.token)
            localStorage.setItem('r-tokentimestamp', Date.now())
            localStorage.setItem('r-fctcrediter', result.data.fctcrediter)
            localStorage.setItem('r-fctrembourser', result.data.fctrembourser)
            localStorage.setItem('r-fctdebloquer', result.data.fctdebloquer)
            localStorage.setItem('r-url', url)

            if (logo !== null) {
              localStorage.setItem('r-logo', logo)
            }
  
            let $this = this;

            $this.setState({
              formSuccess: true,
              formErrors: {},
              successMessage: t("FormSuccess:login-success")
            });

            setTimeout(function() {
               $this.setState({
                isDoingAction:false
              });
              
              history.push({
                pathname: "/dashboard"
              });

             
            }, 2000);

          } else {
            this.setState({
              isDoingAction : false
            });
          }
      },
      (error) => {
  
        //console.log(error)
        this.setState({
          isDoingAction : false,
          formSuccess: false,
          formErrors: {
            error: t("FormErrors:connection-issue")
          }
        });
      });
    }

  }

  queryApi = async (email, password) => {
    const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
    const {t} = this.props;

    await fetch(REACT_APP_API_URL + "/accueil/login/" + email, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-API-KEY': REACT_APP_API_KEY,
        'AUTH-PW': password,
        "Accept" : "*/*"
      }
    })
    .then(res => res.json())
    .then(
      (result) => {

        if (result.reponse === "ok") {

            let url = result.data.url;
            let logo = result.data.logo;
            this.getToken(email, password, url, logo)

        } else {
          
          let errorMessage = "";
          if (result.reponsetexte === "Api key refuse ou manquant") {
            errorMessage = t("FormErrors:missing-api-key")
          }

          if (result.reponsetexte === "mail obligatoire") {
            errorMessage = t("FormErrors:mandatory-email")
          }

          if (result.reponsetexte === "compte non trouve") {
            errorMessage = t("FormErrors:account-not-found")
          }

          if (result.reponsetexte === "Identification erronee") {
            errorMessage = t("FormErrors:login-failure")
          }

          if (result.reponsetexte === "N existe pas") {
            errorMessage = t("FormErrors:doesnt-exist")
          }
          
          this.setState({
            formSuccess: false,
            formErrors: {
              error: errorMessage
            }
          });

          let $this = this;
          setTimeout(function() {
            $this.setState({
              isDoingAction:false
            });
          }, 2000);
        
        }
      },
      (error) => {

        //console.log(error)
        this.setState({
          formSuccess: false,
          formErrors: {
            error: t("FormErrors:connection-issue")
          }
        });

        let $this = this;
        setTimeout(function() {
          $this.setState({
            isDoingAction:false
          });
        }, 2000);
      }
    )
  }

  
  togglePassword = (e) => {
    e.preventDefault()
    this.setState({
      passwordShown : !this.state.passwordShown
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    
      this.onVerify();
  }

  componentDidMount() {
    clearLocalStorage();
    toggleRecaptcha(true);
  }
  
  render() {
    const {i18n, t} = this.props;
    
      return (
        <div className="connect">

          <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
            <title>{t('Login:title')}</title>
            <meta name="description" content={t("Login:desc")} />
            </Helmet>
          </HelmetProvider>
          
          <LanguageSwitcher />

          <div className="wrapper">

            <form onSubmit={this.handleSubmit}>
              
              <Logo className='logo' />

              <div className="form">
              
                <h2>{t('Login:login')}</h2>
                <div className="app-title">{t('Login:app-title')}</div>
                
                <FormErrors formErrors={this.state.formErrors} />
                <FormSuccess formSuccess={this.state.formSuccess} successMessage={this.state.successMessage} />

                <div className="fields">
                  <div className={`form-group ${errorClass(this.state.formErrors.email)}`}>
                      <label htmlFor="email">{t('Login:email-address')}
                        <input type="email" autoComplete="username" id="email" name="email" className="form-control" placeholder={t('Login:enter-email')} onChange={
                          (e) => {
                            handleUserInput(e, this)
                          }
                        } onKeyDown={
                          (e) => {
                            onFieldKeyDown(e, this)
                          }
                        } />
                      </label>
                  </div>
                  <div className={`form-group ${errorClass(this.state.formErrors.password)}`}>
                      <label htmlFor="password">{t('Login:password')}
                        <input id="password" autoComplete="current-password" name="password" type={this.state.passwordShown ? "text" : "password"} className="form-control" placeholder={t('Login:enter-password')} onChange={
                          (e) => {
                            handleUserInput(e, this)
                          }
                        } onKeyDown={
                          (e) => {
                            onFieldKeyDown(e, this)
                          }
                        } />
                        <button className={this.state.passwordShown ? "showHide on" : "showHide"} onClick={this.togglePassword}></button>
                      </label>
                  </div>

                </div>
              </div>
                
              <button type="submit" className="continue">{t('Login:submit')}</button>
            </form>

            <img className="desktop-all bg-login absolute" src={bgLogin} alt={parse(t("Login:login"))} width="560" height="475" />
          </div>
          
        </div>
      );

      
  }
}

export default withTranslation()(withGoogleReCaptcha(Login));
