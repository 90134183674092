import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import '../../../assets/css/components/Dashboard/AccountCreation/ClientList.css';

const ClientList = (props) => {
    const {t} = useTranslation();
    const {token, uuid, filterClients, present, setFields, setFilterClients, setError} = props;
    const url = localStorage.getItem("r-url");
    const [clients, setClients] = useState(null);
    const [clientRef, setClientRef] = useState([]);

    useEffect(()=>{

        if (clients === null) {

            const getClientsList = async() => {
                if (token !== "" && url !== "" && uuid !== null) {
                    await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-clients.awp", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            "Accept": "*/*",
                            "Accept-Encoding": "gzip, deflate, br",
                            "Connection": "keep-alive"
                        },
                        body: JSON.stringify({
                            "token": token,
                            "filtrepresent": 0,
                            "filtresanscompte": 0,
                            "filtrecommence": "",
                            "filtrelimite":0
                        })
                    })
                    .then(res => res.json())
                    .then(
                    (result) => {
        
                        if (result.resultat === "ok") {
                            setClients(result.data.clients);
                        }
        
                        if (result.resultat === "erreur") {
                           
                        }
                        
                    },
                    (error) => {
                        // console.log(error)
                    });
                }
            }
    
            getClientsList();
        }

        if (clients !== null) {
            setClientRef(clients.filter((i) => {
                let condition = false;
                if (filterClients.length >= 2) {
                    condition = (i.nom.toLowerCase().includes(filterClients) && Boolean(i.present) === present) || filterClients === ''
                } else {
                    condition = (i.nom.toLowerCase() && Boolean(i.present) === present) || filterClients === ''
                }
                return condition;
            }));
    
        }
        
        
    }, [token, uuid, clients, url, filterClients, present]);
    
    return (
        <div className={`clients-list bg-white absolute full-width relative flex flex-direction-row align-items-start justify-content-start box-shadow${clientRef.length === 0 || filterClients === "" ? " hide" : ""}`}>
        
            <div className={`list table`}>
                {(clientRef !== null ) && 
                    <div className="table-heading">
                        <div className="table-row cl-grey-dark bold">
                            <div className="row-1">{parse(t("ClientList:nom"))}</div>
                            <div className="row-2">{parse(t("ClientList:prenom"))}</div>
                            <div className="row-3">{parse(t("ClientList:email"))}</div>
                        </div>
                    </div>
                }
                <div className={`table-body`}>
               
                {clientRef !== null && clientRef.length > 0 && clientRef.map((obj, i) => {
                    return(
                        <div key={i} className="table-row item" onClick={(e) => {
                            setFields(prevState => {
                                return ({ ...prevState, 
                                    idclient: obj.idclient,
                                    nom: obj.nom,
                                    prenom: obj.prenom,
                                    email: obj.email,
                                    datedebut: obj.datedebut,
                                    datefin: obj.datefin,
                                    montantdecouvert: obj.montantdecouvert,
                                    nbparticipants: obj.nbparticipants,
                                    hebergement: obj.hebergement,
                                    tel: obj.Tel,
                                });
                            });

                            setClientRef({});
                            setFilterClients("");
                            setError({
                                prenom:false, 
                                nom: false, 
                                email:false, 
                                emailWrong:false
                            })

                        }}>
                            <div className="row-1 nom"><span>{obj.nom}</span></div>
                            <div className="row-2 prenom"><span>{obj.prenom}</span></div>
                            <div className="row-3 email"><span>{obj.email}</span></div>
                        </div>
                    )
                })}
                    

                
                </div>
            </div>
        </div>
    )
};

export default withTranslation()(ClientList);