
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetProvider, Helmet } from "react-helmet-async";
import parse from 'html-react-parser';
import {replaceDot} from '../Helpers';
import { useReactToPrint } from 'react-to-print';
import OperationsList from './OperationsList';
import '../../assets/css/components/Account/Operations.css';

const Operations = (props) => {
    const {i18n, t} = useTranslation();
    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);
    const {data, match, setEditCardModal, contestationModal, setContestableOps, contestableOps} = props;
    const logo = localStorage.getItem("r-logo");
    const [printDate, setPrintDate] = useState(null);
    const [isPrinting, setIsPrinting] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    let currAnnexeCard = null;
        
    if(Number(data.id) === Number(match.params.id) || Number(match.params.id) === 0) {

        Object.keys(data.braceletannexes).forEach((i) => {
            let obj = data.braceletannexes[i];
            
            if (Number(match.params.cid) === Number(obj.id)) {
                currAnnexeCard = obj;
            }
        });
    }

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
          promiseResolveRef.current();
        }
      }, [isPrinting]);

    return(
        <>
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{parse(t('Operations:title'))}</title>
                <meta name="description" content={parse(t("Operations:desc"))} />
            </Helmet>
        </HelmetProvider>
        {data !== null && (match.params.cid === undefined || Number(match.params.cid) === 0 || Number(match.params.cid) === Number(currAnnexeCard?.id)) &&
            <div className="operations">
                <div className='panel-title bracelet'>{parse(t("Operations:cards"))}</div>

                <div className='operations-container relative' ref={componentRef}>
                
                    <div className='establishment bolder-x absolute'>{localStorage.getItem("r-etablissement")}</div>

                    <div className="header text-center">
                        
                        <p className='logo-print text-center'>
                            {logo !== null &&
                            <>
                                <img src={logo} alt="" width="" height="" /><br/>
                            </>
                            }
                            {printDate !== null &&
                            <span>
                                {parse(t("Operations:document-generated-on", {date:printDate}))}
                            </span>
                            }
                        </p>

                        {Number(match.params.cid) === 0 &&
                        <>
                            <div className='card-type bolder'>{parse(t("Operations:main-card"))}</div>
                            <div className='card-name bolder-x cl-turquoise'>{data.nom}</div>
                            
                            {data.texteutilisateur !== null && data.texteutilisateur !== "" &&
                                <div className='card-name-web bolder-x cl-turquoise'>{parse(t("Operations:web-name", {name:data.texteutilisateur}))}</div>
                            }
                            
                            <div className='limit normal'>{parse(t("Operations:no-limit"))}</div>
                        </>
                        }

                        {match.params.cid === undefined &&
                        <>
                            <div className='card-type all-cards bolder cl-turquoise'>{parse(t("Operations:all-cards"))}</div>
                        </>
                        }

                        {match.params.cid !== undefined && Number(match.params.cid) !== 0 && currAnnexeCard !== null &&
                            <>
                            <div className='card-type bolder'>{parse(t("Operations:aux-card"))}</div>
                            <div className='card-name bolder-x cl-turquoise'>{currAnnexeCard.textelogiciel}</div>
                            {currAnnexeCard.limitemontant === 0 && currAnnexeCard.limitetype === "aucune" && !currAnnexeCard.bloqueetat &&
                                <div className='limit normal'>{parse(t("Operations:no-limit"))}</div>
                            }

                            {currAnnexeCard.limitemontant > 0 && currAnnexeCard.limitetype !== "aucune" && !currAnnexeCard.bloqueetat &&
                                <div className='limit normal'>
                                    {parse(t("Operations:limit", {number:currAnnexeCard.limitemontant, type:parse(t("Operations:" + currAnnexeCard.limitetype)),devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}<br/>
                                    {Number(currAnnexeCard.disponible) >= 0 &&
                                    <i>{parse(t("Operations:available", {number:replaceDot(currAnnexeCard.disponible),devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</i>
                                    }
                                </div>
                            }

                            {currAnnexeCard.bloqueetat &&
                                <div className='limit normal'>{parse(t("Operations:blocked-card"))}</div>
                            }

                            {!currAnnexeCard.bloqueetat &&
                                <button className="edit" onClick={(e)=>{
                                    setEditCardModal(true);
                                }}><span>{parse(t("Operations:edit-card"))}</span></button>
                            }
                            </>
                        }
                    </div>
                    
                

                {data !== null && 
                    <OperationsList isPrinting={isPrinting} handlePrint={handlePrint} setPrintDate={setPrintDate} contestationModal={contestationModal} setContestableOps={setContestableOps} contestableOps={contestableOps} {...props} />
                }

                </div>
            </div>
        }
        </>
    );

      
}

export default Operations;