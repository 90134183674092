import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { formatDate, todayDate, replaceDot } from "../Helpers";
import { useTranslation } from 'react-i18next';
import '../../assets/css/components/Account/OperationsList.css';

const OperationsList = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const {data, match, setContestationModal, handlePrint, isPrinting, setPrintDate, setContestableOps, contestableOps} = props;

    useEffect(()=> {
        let tmpObj = {};
        if (contestableOps === null) {
            Object(data.mouvements?.filter((i) => {

                if (match.params.cid !== undefined) {
                    return Number(match.params.cid) === Number(i.idbraceletannexe)
                } else {
                    return true;
                }
    
            })).forEach((obj, i)=> {
                tmpObj["contested-"+i] = false;
            });
    
            setContestableOps(tmpObj);
        }

        
    }, [data, match, setContestableOps, contestableOps]);
    return (
        <div className='operations-list relative'>
        
        
        {data.mouvements?.length > 0 && data.mouvements.filter((i) => {

        if (match.params.cid !== undefined) {
            return Number(match.params.cid) === Number(i.idbraceletannexe)
        } else {
            return true;
        }

        }).length > 0 &&

        <div className="table-head flex flex-direction-row justify-content-between align-items-center">
            <div className='table-title cl-turquoise bolder-x'>{parse(t("Operations:recent"))}</div>
            <button className="print" onClick={()=> {
                
                if (!isPrinting) {
                    setPrintDate(todayDate());
                
                    setTimeout(function() {
                        handlePrint();
                    }, 4);
                }
                
            }}><span>{parse(t("Operations:print"))}</span></button>
        </div>
        }

        <div id="operations" className={`list table full-width`}>

            {data.mouvements?.length === 0 &&
                <div className="no-ops">{parse(t("Operations:no-ops"))}</div>
            }
                   
            {data.mouvements?.length > 0 && data.mouvements.filter((i) => {

            if (match.params.cid !== undefined) {
                return Number(match.params.cid) === Number(i.idbraceletannexe)
            } else {
                return true;
            }

            }).length > 0 && 
                <div className="table-heading">
                    <div className="table-row cl-grey-dark bold">
                        <div className="row-1">{parse(t("Operations:date-label"))}</div>
                        <div className="row-2">{parse(t("Operations:card-label"))}</div>
                        <div className="row-3">{parse(t("Operations:operation-reason-label"))}</div>
                        <div className="row-4 text-center">{parse(t("Operations:contestation-label"))}</div>
                        <div className="row-5 text-right">{parse(t("Operations:amount-label"))}</div>
                    </div>
                </div>
            }

            <div className={`table-body`}>
            {data.mouvements?.length > 0 && data.mouvements?.filter((i) => {

                if (match.params.cid !== undefined) {
                    return Number(match.params.cid) === Number(i.idbraceletannexe)
                } else {
                    return true;
                }

            }).map((obj, i) => {
                let name = obj.idbraceletannexe === 0 ? data.nom : obj.nombracelet;
                let date = obj.dateheure?.substring(0, 8);
                let hh = obj.dateheure?.substring(8, 10);
                let mm = obj.dateheure?.substring(10, 12);
              

                return(
                    <div key={i} className='operation table-row'>
                        <div className="row-1"><span>{formatDate(date)}<br/>{parse(t("Operations:time-format", {hh:hh, mm:mm}))}</span></div>
                        <div className="row-2"><span>{parse(name)}</span></div>
                        <div className="row-3"><span>{(obj.nomboutique !== "" ? parse(obj.nomboutique) + " : " : "") + parse(obj.explication)}</span>
                            {obj.commentairecontestation !== "" &&
                            <>
                            {!obj.contestable &&
                            <>
                            <br/><em className={`${obj.contested ? "contested " : ""}`}>{obj.commentairecontestation}</em>
                            </>
                            }
                            </>
                            }
                        </div>
                        <div className="row-4 text-center">
                            {obj.contestable &&
                            <div className="label-styled flex flex-direction-row align-items-center justify-content-center relative">
                                <input type="checkbox" name={`contested-${i}`} id={`contested-${i}`} disabled={false} checked={contestableOps !== null ? contestableOps[`contested-${i}`] : false} onChange={(e)=>{
                                    
                           
                                    setContestableOps(prevState=>({
                                        ...prevState,
                                        [`contested-${i}`] : true
                                    }));

                                    setContestationModal(prevState => ({
                                        ...prevState,
                                        state:true, 
                                        obj:obj,
                                        contested:i
                                    }));
                                    
                                }} />
                                <label className="fake-label bg-grey-dark flex flex-direction-row align-items-center justify-content-center relative" htmlFor={`contested-${i}`}></label>
                            </div>
                            }

                            {obj.contested &&
                            <div className="label-styled flex flex-direction-row align-items-center justify-content-center relative">
                                <input type="checkbox" name={`contested-${i}`} id={`contested-${i}`} disabled={true} checked={true} />
                                <label className="fake-label bg-grey-dark flex flex-direction-row align-items-center justify-content-center relative contested" htmlFor={`contested-${i}`}></label>
                            </div>
                            }
                        </div>
                        <div className="row-5 bolder-x text-right uppercase"><span>{parse(t("Operations:amount", {amount:replaceDot(obj.montant),devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</span></div>
                    </div>
                )
            })}
            </div>
        </div>
    </div>
    )
});

export default OperationsList;