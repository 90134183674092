import i18n from 'i18next';
import { useEffect } from 'react';
import parse from 'html-react-parser';

export const langs = {
  fr : {
    name : "français",
    flag : "FR"
  }

  // en : {
  //   name : "english",
  //   flag : "GB"
  // },

  // es : {
  //   name : "español",
  //   flag : "ES"
  // },

  // it : {
  //   name : "italiano",
  //   flag : "IT"
  // }
    
}



export const handleUserInput = (e, component) => {

  const name = e.target.name;
  const value = e.target.value;

  component.setState({[name]: value}, () => { 
    component.validateField(name, value) 
  });
}
  
export const onFieldKeyDown = (e, component) => {
  if (e.key === "Enter" && component !== null) {
    component.handleSubmit(e)
  }
}


export const toggleDropDown = (selector) => {
  
  if (selector !== null) {
    if(document.querySelector(selector + " .dropdown") !== null) {
      let dropdown = document.querySelector(selector + " .dropdown");
  
      if (!dropdown.classList.contains("on")) {
        dropdown.classList.add("visible")
        setTimeout(function() {
          dropdown.classList.add("on")
        }, 100)
      } else {
        dropdown.classList.remove("on")
        setTimeout(function() {
          dropdown.classList.remove("visible")
        }, 150)
      }
  
    }
  }
 
}

export const errorClass = (error) => {
  return(error === '' ? '' : 'has-error');
}

//-> Fix vh sur mobiles
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});



export const toggleRecaptcha = (show) => {
  
  let interval = setInterval(function() {
    let recaptchaBadge = document.querySelector(".grecaptcha-badge");

    if (recaptchaBadge !== null) {
  
      if (show) {
        recaptchaBadge.style.display = "block"
      } else {
        recaptchaBadge.style.display = "none"
      }

      clearInterval(interval);
    }
  }, 4);
  
}


export const abortableFetch = (request, opts) => {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    ready: fetch(request, { ...opts, signal })
  };
}



export const useScript = (id, url, integrity, crossOrigin) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (document.getElementById(id) === null) {
      script.id = id;
      script.src = url;
      script.async = true;
      script.integrity = integrity;
      script.crossOrigin = crossOrigin;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, [id, url, integrity, crossOrigin]);
};


export const formatDate = (date, hours) => {
    
  let fDate = "";

  if (date !== undefined && date !== "") {
    let d = date.substring(6, 8)
    let m = date.substring(4, 6)
    let y = date.substring(0, 4)
    let h = date.substring(8, 10)
    let min = date.substring(10, 12)
    let s = date.substring(12, 14)
    let tmpDate = new Date(+y, m-1, +d, +h, +min, +s)

    let hoursObj = {
      year: "numeric",
      month: "long",
      day: "2-digit"
    };

    if (hours) {
      hoursObj.hour = "2-digit";
      hoursObj.minute = "2-digit";
      hoursObj.hour12 = false;
    }

    if (tmpDate !== "Invalid Date") {
        fDate = new Intl.DateTimeFormat(i18n.language, hoursObj).format(tmpDate)
    }
  }

  return fDate
}

export const todayDate = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  let h = String(today.getHours()).padStart(2, '0')
  let m = String(today.getMinutes()).padStart(2, '0')
  let s = String(today.getSeconds()).padStart(2, '0')
  today = formatFullDate(yyyy + "" + mm + "" + dd + "" + h + "" + m + "" + s);
  return today;
};

export const formatFullDate = (date) => {

  let fDate = "";
  
  if (date !== undefined && date !== "") {

    let d = date.substring(6, 8)
    let m = date.substring(4, 6)
    let y = date.substring(0, 4)
    let h = date.substring(8, 10)
    let min = date.substring(10, 12)
    let s = date.substring(12, 14)
    let tmpDate = new Date(+y, m-1, +d, +h, +min, +s)
  
    if (tmpDate !== "Invalid Date") {
        fDate = new Intl.DateTimeFormat(i18n.language, {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).format(tmpDate)
    }
  }

  return fDate.replace(',','').replace('à','')
}






export const clearLocalStorage = () => {
  let keys = [
    "r-loggedIn",
    "r-fctcrediter",
    "r-fctrembourser",
    "r-fctdebloquer",
    "r-token",
    "r-tokentimestamp",
    "r-etablissement",
    "r-url",
    "r-logo",
    "r-data"
  ]

  for (let key of keys) {
    localStorage.removeItem(key)
  }
}



export const replaceDot = (str) => {

  let newStr = str;

  if (str !== undefined && str !== null) {
      newStr = str.toString().replace(".", ",")
  }

  return newStr;
}

export const replaceComma = (str) => {

  let newStr = str;

  if (str !== undefined && str !== null) {
      newStr = str.toString().replace(",", ".")
  }

  return newStr;
}

export const testCurrentToken = (token, url, history) => {

  if (token !== null && token !== "" && url !== null && url !== "" && history !== null && history !== "") {

    let storedTimestamp = Number(localStorage.getItem("r-tokentimestamp"));
    
    if (storedTimestamp !== null) {
        if(Date.now() - storedTimestamp > 3600e3){
            testToken(token, url).then((result) => {
                if (!result) {
                    clearLocalStorage();
                    history.push({
                        pathname:"/login"
                    });
                }
            });
        }
    }
  }
}

export const testToken = async(tokenLive, url) => {
  if (tokenLive !== "" && url !== "") {
      return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-testtoken.awp", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              "Accept": "*/*",
              "Accept-Encoding": "gzip, deflate, br",
              "Connection": "keep-alive"
          },
          body: JSON.stringify({
              "token": tokenLive
          })
      })
      .then(res => res.json())
      .then(
      (result) => {
          let returnVal = false;
          if (result.resultat === "ok") {
              returnVal = true;
          }

          return returnVal;
      },
      (error) => {
          console.log(error)
      });
  } else {
      return false;
  }
  
};

export const getAllAccounts = async(token, url) => {
  if (token !== "" && url !== "") {
      return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-compteactifs.awp", {
      // await fetch("https://osmopay.interaview.com/mockup.json", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              "Accept": "*/*",
              "Accept-Encoding": "gzip, deflate, br",
              "Connection": "keep-alive"
          },
          body: JSON.stringify({
              "token": token
          })
      })
      .then(res => res.json())
      .then(
      (result) => {

        let data = null;
        
        if (result.resultat === "ok") {
          data = result.data;
        }
        return data;
      },
      (error) => {
          console.log(error)
      });
  }
  
}

export const getSingleAccount = async(token, id, url) => {
  if (token !== "" && url !== "") {
      return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-compte.awp", {
      // await fetch("https://osmopay.interaview.com/mockup.json", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              "Accept": "*/*",
              "Accept-Encoding": "gzip, deflate, br",
              "Connection": "keep-alive"
          },
          body: JSON.stringify({
              "token": token,
              "id": id
          })
      })
      .then(res => res.json())
      .then(
      (result) => {

        let data = null;
        
        if (result.resultat === "ok") {
          data = result.data;
        }
        return data;
      },
      (error) => {
          console.log(error)
      });
  }
  
}


export const createAccount = async(token, url, data, setMessage, history, callback) => {
  
  if (token !== "" && url !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-creationcompte.awp", {
    // await fetch("https://osmopay.interaview.com/mockup.json", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json());
  }
}

export const blockAccount = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idprincipal !== "" && data.uuidprincipal !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-blocagecompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json());
  }
};

export const unblockAccount = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idprincipal !== "" && data.uuidprincipal !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-deblocagecompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json());
  }
};


export const blockCard = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idprincipal !== "" && data.uuidprincipal !== "" && data.idannexe !== "" && data.uuidannexe !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-blocageannexe.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const unblockCard = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idprincipal !== "" && data.uuidprincipal !== "" && data.idannexe !== "" && data.uuidannexe !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-deblocageannexe.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};


export const replaceMainCard = async(api, url, data) => {
  if (api !== "" && url !== "" && data.token !== "" && data.idcompte !== "" && data.uuidcompte !== "" && data.uuidnouveau !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-" + api + ".awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};


export const loadPaymentTypes = async(url, token) => {
  if (url !== "" && token !== "") {

    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-modesreg.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify({token:token})
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const reloadAccount = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idcompte !== "" && data.uuidcompte !== "" && data.monde !== "" && data.montant !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-creditercompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const sendReceipt = async(url, data) => {
  if (url !== "" && data.token !== "" && data.id !== "" && data.uuid !== "" && data.idmvt !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-mailrecu.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const refundAccount = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idcompte !== "" && data.uuidcompte !== "" && data.monde !== "" && data.montant !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-remboursercompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};


export const sendOperations = async(url, data) => {
  if (url !== "" && data.token !== "" && data.id !== "" && data.uuid !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-mailcompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const closeAccount = async(url, data) => {
  if (url !== "" && data.token !== "" && data.idcompte !== "" && data.uuidcompte !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-cloturercompte.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const refundsList = async(url, token, id, uuid) => {
  if (url !== "" && token !== "" && id !== "" && uuid !== "") {
    return await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-listedemanderemboursement.awp", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive"
        },
        body: JSON.stringify({
          token:token,
          id:id,
          uuid:uuid
        })
    })
    .then(res => res.json(),
    (error) => {
       console.log(error);
    });
  }
};

export const splitCards = (data) => {
  
  let activeCards = null;
  let replacedCards = null;
  
  if (data !== null) {
    activeCards = [];
    replacedCards = [];
    Object(data.braceletannexes).forEach((obj, i) => {
        if (obj.remplaced) {
            replacedCards.push(obj);
        } else {
            activeCards.push(obj);
        }
    });
  
  }

  return [activeCards, replacedCards];
};

export const checkText = (text, index) => {
  if (text !== "") {
      return parse(text)
  } else {
     if(index === undefined) {
        index = 0
     }
     
     if (index === 0) {
      return parse(i18n.t("Accounts:main-card-raw"))
     } else {
      return parse(i18n.t("Accounts:card") + " " + index)
     }
     
  }
}

export const checkCardName = (card) => {
  
  if (card.texteclient !== undefined && card.texteclient !== "") {
      return card.texteclient
  } else {
      if (card.textepms !== undefined) {
          return card.textepms
      } else {
          return ""
      }
  }
}
