import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import { toggleRecaptcha } from '../components/Helpers';
import XHR from "i18next-http-backend";

i18n.on('initialized', function (lng) {

});

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: { 
      useSuspense: false
    },
    compatibilityJSON: 'v3',
    fallbackLng: 'fr',
    resources: {
    fr: {
      Account: require('./locales/fr/Account.json'),
      AccountCreation: require('./locales/fr/AccountCreation.json'),
      AccountInfo: require('./locales/fr/AccountInfo.json'),
      AccountsList: require('./locales/fr/AccountsList.json'),
      AddCardModal: require('./locales/fr/AddCardModal.json'),
      CardList: require('./locales/fr/CardList.json'),
      ClientList: require('./locales/fr/ClientList.json'),
      ContestationModal: require('./locales/fr/ContestationModal.json'),
      Dashboard: require('./locales/fr/Dashboard.json'),
      EditCardModal: require('./locales/fr/EditCardModal.json'),
      FormErrors: require('./locales/fr/FormErrors.json'),
      FormSuccess: require('./locales/fr/FormSuccess.json'),
      Infos: require('./locales/fr/Infos.json'),
      Login: require('./locales/fr/Login.json'),
      Main: require('./locales/fr/Main.json'),
      ManageCards: require('./locales/fr/ManageCards.json'),
      Menu: require('./locales/fr/Menu.json'),
      NDEFButton: require('./locales/fr/NDEFButton.json'),
      NDEFReaderModal: require('./locales/fr/NDEFReaderModal.json'),
      NotFound: require('./locales/fr/NotFound.json'),
      Operations: require('./locales/fr/Operations.json'),
      ReadCardModal: require('./locales/fr/ReadCardModal.json'),
      Reload: require('./locales/fr/Reload.json'),
      Close: require('./locales/fr/Close.json'),
      SearchBox: require('./locales/fr/SearchBox.json'),
      UserMenu: require('./locales/fr/UserMenu.json')
    },
  },
  whitelist: ['fr'],
  ns: [
    'Account',
    'AccountCreation',
    'AccountInfo',
    'AccountsList',
    'AddCardModal',
    'CardList',
    'ClientList',
    'ContestationModal',
    'Dashboard',
    'EditCardModal',
    'FormErrors', 
    'FormSuccess', 
    'Infos', 
    'Login',
    'Main',
    'ManageCards',
    'Menu',
    'NDEFButton',
    'NDEFReaderModal',
    'NotFound',
    'Operations',
    'ReadCardModal',
    'Reload',
    'Close',
    'SearchBox',
    'UserMenu'
  ],
  detection: {
    order: ["localStorage", "cookie", "navigator"],
    caches: ["localStorage", "cookie"],
    lookupFromPathIndex: 0,
    checkWhitelist: true
  },
  debug:false,
  interpolation: {
    escapeValue: false,
    formatSeparator: '.'
  }
});

i18n.languages = ['fr'];

i18n.on('languageChanged', function (lng) {
  if (window.location.href.indexOf("dashboard") !== -1) {
    setTimeout(function() {
      toggleRecaptcha(false)
    }, 150)
  }
})

export const getLanguage = () => {
  if(i18n.language !== "fr") {
    return '/' + i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    '';
  } else {
    return '';
  }
}


export const getLanguageCode = () => {
    return i18n.language;
}

export const baseRouteUrl = i18n.language === "fr" ? "" : "/" + i18n.language
export default i18n
