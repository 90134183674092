import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { replaceDot, formatDate } from '../Helpers';
import parse from 'html-react-parser';
import '../../assets/css/components/Dashboard/AccountsList.css';

const AccountsList = (props) => {
    const {t} = useTranslation();
    const {data, filter, history} = props;

    const accounts = data?.comptesactifs;

    return (
      <div className="accounts-list full-width relative flex flex-direction-row align-items-center justify-content-start">
        
            <div className={`list table`}>
                {(accounts?.length > 0 && accounts !== undefined) && 
                    <div className="table-heading">
                        <div className="table-row cl-grey-dark bold">
                            <div className="row-1">{parse(t("AccountsList:name"))}</div>
                            <div className="row-2">{parse(t("AccountsList:state"))}</div>
                            <div className="row-3">{parse(t("AccountsList:bracelet-number"))}</div>
                            <div className="row-4">{parse(t("AccountsList:created"))}</div>
                            <div className="row-5">{parse(t("AccountsList:dep"))}</div>
                            <div className="row-6">{parse(t("AccountsList:balance"))}</div>
                        </div>
                    </div>
                }
                <div className={`table-body`}>
                {accounts !== undefined && accounts?.length > 0 && accounts.filter((i) => {
                    let condition = false;
                    if (filter.length >= 2) {
                        condition = i.nom.toLowerCase().includes(filter) || filter === ''
                    } else {
                        condition = i.nom.toLowerCase() || filter === ''
                    }
                    return condition;
                }).map((obj, i) => {
                    return(
                        <div key={i} className="table-row item" onClick={(e)=>{
                            // window.location.href = "/account/" + obj.id;
                            history.push({
                                pathname: "/account/" + obj.id
                            });
                        }}>
                            <div className="row-1 name"><span>{obj.nom}</span></div>
                            <div className={`row-2 state${obj.bloqueetat ? " blocked" : ""}`}><span>{obj.bloqueetat ? parse(t("AccountsList:blocked")) : ""}</span></div>
                            <div className="row-3 bracelet-number"><span>{obj.nombrebracelet}</span></div>
                            <div className="row-4 created"><span>{formatDate(obj.datecreation)}</span></div>
                            <div className="row-5 dep"><span>{formatDate(obj.datedepart.substring(0, 8))}</span></div>
                            <div className="row-6 amount bolder-x uppercase">
                                <span>
                                    {
                                        parse(
                                            t("AccountsList:balance-number", {
                                                balance: replaceDot(obj.solde),
                                                devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"
                                            })
                                        )
                                    }
                                </span>
                            </div>
                        </div>

                    )
                })}

                {(accounts?.length === 0 || accounts === undefined) && 
                    <div className="table-body">
                        <div className="table-row">
                            <div>{parse(t("AccountsList:no-accounts"))}</div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(AccountsList)