
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { loadPaymentTypes, reloadAccount, replaceComma, sendReceipt } from "../Helpers";
import '../../assets/css/components/Account/Reload.css';

const Reload = (props) => {
    const {i18n, t} = useTranslation();
    const {data, setData } = props;
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const canCredit = localStorage.getItem("r-fctcrediter") === 'true';
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [opID, setOpID] = useState(null);
    const [amountError, setAmountError] = useState("");
    const [modeError, setModeError] = useState("");
    const [message, setMessage] = useState(null);
    const [isDoingAction, setIsDoingAction] = useState(false);

    const [formData, setFormData] = useState({
        token:token,
        idcompte:data.id,
        uuidcompte:data.braceletprincipal,
        mode:"",
        montant:""
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!isDoingAction) {
            setIsDoingAction(true);

            let tmpObj = formData;
            tmpObj.montant = Number(replaceComma(tmpObj.montant));
    
            if (tmpObj.montant === 0 || tmpObj.mode === "") {
                if (tmpObj.montant === 0) {
                    setAmountError(parse(t("Reload:amount-not-selected")));
                } else {
                    setAmountError("");
                }
    
                if (tmpObj.mode === "") {
                    setModeError(parse(t("Reload:mode-not-selected")));
                } else {
                    setModeError("");
                }

                setIsDoingAction(false);
            } else {
                reloadAccount(url, tmpObj).then((result) => {
                    
                    setIsDoingAction(false);
                    
                    if (result.resultat === "ok") {
                        setModeError("");
                        setAmountError("");
                        setOpID(result.data.idmouvement);
                        setData(result.data);
                        setFormData(prevState => ({
                            ...prevState,
                            montant:"",
                            mode:""
                        }));
                        
                        setMessage(parse(t("Reload:loading-performed")));
                    }
                
                    if (result.resultat === "erreur") {
                        if (result.reponse === "Pb TOKEN") {
                            setMessage(parse(t("Reload:token-unrecognized")));
                        }
    
                        if (result.reponse === "Identification compte") {
                            setMessage(parse(t("Reload:id-uuid-mismatch")));
                        }
    
                        if (result.reponse === "Mode non autorisé") {
                            setMessage(parse(t("Reload:unauthorized-mode")));
                        }
    
                        if (result.reponse === "Montant incohérent") {
                            setMessage(parse(t("Reload:amount-issue")));
                        }
    
                        if (result.reponse === "Données manquantes") {
                            setMessage(parse(t("Reload:missing-data")));
                        }
                    }

                });
            }
        }
        

        
    };
    

    useEffect(() => {
        let isActive = true;

        if (canCredit) {
            loadPaymentTypes(url, token).then((result) => {
                if (isActive) {
                    if (result.resultat === "ok") {
                        setPaymentTypes(result.data);
                    }
        
                    if (result.resultat === "erreur") {
                        if (result.reponse === "Pb TOKEN") {
                            setMessage(parse(t("Reload:token-issue")));
                        }
                    }
                }
                
            });
        }

        return () => isActive = false;
    }, [token, url, t, canCredit]);

    return(
        <>
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{parse(t('Reload:title'))}</title>
                <meta name="description" content={parse(t("Reload:desc"))} />
            </Helmet>
        </HelmetProvider>
        {data !== null &&
            <div className="reload relative ">

                <div className='header'>
                    <div className='panel-title blocage'>{parse(t("Reload:loading"))}</div>
                </div> 

                <div className='reload-container relative flex flex-direction-column align-items-evenly justify-content-center'>
                    <div className="relative title bolder-x cl-turquoise">{parse(t("Reload:fill-in-account"))}</div>
                    
                    <div className='load-box-container relative flex flex-direction-column align-items-center justify-content-evenly bg-grey-light'>
                        <div className='loadbox relative flex flex-direction-row align-items-center justify-content-evenly'>
                            {canCredit &&
                            <>
                                <div className='relative part-1'>
                                    <span className='relative input-title bolder-x'>{parse(t("Reload:choose-payment-method"))}</span>
                                    <div className="input relative">
                                        <p>
                                            <label className='relative label group-item flex flex-direction-row text-left normal align-items-start relative' htmlFor='payment-type'>
                                                <select name="payment-type" id="payment-type" value={formData.mode} onChange={(e)=> {
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        mode:e.target.value
                                                    }));

                                                    setModeError("");
                                                }}>
                                                <option value="" disabled>{parse(t("Reload:select-payment-method"))}</option>
                                                {paymentTypes !== null && Object(paymentTypes).map((obj, i)=> {
                                                    return (
                                                        <option key={i} value={obj.code}>{obj.designation}</option>
                                                    )
                                                })}
                                                </select>
                                            </label>
                                        </p>
                                    </div>
                                    <em className='cl-error1 bolder-x absolute'>{modeError}</em>
                                </div>

                                <div className='relative part-2'>
                                    <span className='relative input-title bolder-x'>{parse(t("Reload:enter-amount"))}</span>
                                    <div className="input relative">
                                        <p>
                                            <label className='relative label group-item flex flex-direction-row text-left normal align-items-start relative' htmlFor='amount'>
                                                <input min="0" step="0.01" type="number" name="amount" id="amount" value={formData.montant} onChange={(e)=>{
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        montant:e.target.value
                                                    }));

                                                    if (e.target.value !== "" && Number(e.target.value) > 0) {
                                                        setAmountError("");
                                                    }
                                                }}/><span>{data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</span>
                                            </label>
                                        </p>
                                    </div>
                                    <em className='cl-error1 bolder-x absolute'>{amountError}</em>
                                </div>
                            </>
                            }

                            {!canCredit &&
                            <div className='no-reload flex flex-direction-column text-center'>
                                <div className='no-reload-title bolder-x'>{parse(t("Reload:client-info"))}</div>
                                <div className='no-reload-content'>{parse(t("Reload:client-info-details"))}</div>
                            </div>
                            }
                        </div>

                        {canCredit &&
                        <button className='load bolder-x uppercase' onClick={handleSubmit}>{parse(t("Reload:load"))}</button>
                        }
                    </div>

                    {opID !== null && canCredit &&
                        <button className='send-receipt' onClick={(e)=> {
                            let tmpObj = {
                                "token": token,
                                "id" : data.id,
                                "uuid" : data.braceletprincipal,
                                "idmvt" : opID
                            }
                            sendReceipt(url, tmpObj);
                            setMessage(parse(t("Reload:receipt-sent")));
                            setOpID(null);
                        }}>{parse(t("Reload:send-receipt"))}</button>
                    }
                    

                </div>

                
                
            </div>

        }

        {message !== null && 
            <div className={`message-popup${message?.text !== null ? " on" : ""} full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}><div className="message-container relative bg-white box-shadow"><button className="close absolute" onClick={(e) => {
                setMessage(null);
            }}></button><div className="message">{message}</div></div></div>
        }
        </>
    );

      
}

export default Reload;