import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import bgReader from '../../../assets/images/reader.png';
import bgLoader from '../../../assets/images/loader-bracelet.svg';
import '../../../assets/css/components/Dashboard/Modals/NDEFReaderModal.css';

const NDEFReaderModal = (props) => {
    const {t} = useTranslation();
    const { setModal, history, setCreateAccount, setMessage } = props;
    const timerRef = useRef(null);
    const showTimerRef = useRef(null)
    const [counter, setCounter] = useState(5);
    const [showTimer, setShowTimer] = useState(false);
    const ws = useRef(null);
    const uuid = useRef(null);
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const [connectionError, setConnectionError] = useState(false);
    const [cardDetected, setCardDetected] = useState(false);

    useEffect(()=> {
        let isActive = true;

        const testUUID = async() => {
            if (token !== "" && url !== "" && uuid.current !== null) {
                await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-testuuid.awp", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        "Accept": "*/*",
                        "Accept-Encoding": "gzip, deflate, br",
                        "Connection": "keep-alive"
                    },
                    body: JSON.stringify({
                        "token": token,
                        "uuid": uuid.current,
                    })
                })
                .then(res => res.json())
                .then(
                (result) => {
             
                    if (result.resultat === "ok") {
                        if (result.reponse === "existe") {
                            let bracelet = "";
                            let replacedTest = false;
                            Object.keys(result.data.braceletannexes).forEach((i) => {
                                let obj = result.data.braceletannexes[i];
                                
                                if (obj.bracelet === uuid.current) {
                                    bracelet = "/" + obj.id;
                                    if (obj.remplaced || obj.textelogiciel === "ancien bracelet principal") {
                                        replacedTest = true;
                                    }
                                }
                            });

                            if (!replacedTest) {
                                if (bracelet === "") {
                                    bracelet = "/0";
                                }
    
                                history.push({
                                    pathname: "/account/" + result.data.id + bracelet,
                                });
                            } else {
                                setMessage(parse(t("NDEFReaderModal:replaced-card")));
                            }
                           
                            setConnectionError(false);
                            setCardDetected(false);

                        }
                    }

                    if (result.resultat === "libre") {
                        setCreateAccount(true);
                        history.push({
                            pathname: "/dashboard/create-account/" + uuid.current,
                        });
                        
                        setConnectionError(false);
                        setCardDetected(false);
                    }
                    
                    
                },
                (error) => {
                    setMessage(parse(error));
                });
            }
        };
        
        if (!connectionError) {

            const { REACT_APP_LOCALSERVER_URL } = process.env;
            ws.current = new WebSocket(REACT_APP_LOCALSERVER_URL);
    
            ws.current.onmessage = function(event) {
            
                let serialNum = JSON.parse(event.data).rfid;
    
                if(serialNum !== "") {
                    setCardDetected(true);
                    uuid.current = serialNum;
    
                    if (isActive) {
                        if (uuid.current !== null) {
                            testUUID();
                        }
                    }
                    
                    if (ws.current.readyState === 1) {
                        ws.current.send("RFID_OFF");
                        setTimeout(function() {
                            ws.current.send("CLOSE");
                            ws.current.onopen = function(e) {};
                            ws.current.onclose = function(e) {};
                            ws.current.close();
                        }, 100);
                    }
    
                    setModal(false);
                }
            
            };
    
            ws.current.onclose = function(event) {
                
                if (event.wasClean) {
                    // console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                    if (event.code === 1006) {
                        // console.log(event);
                    }
                    
                }
            };
    
            ws.current.onopen = function(e) {
                // console.log("[open] Connection established");
                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_ON");
                }
            };
    
            ws.current.onerror = function(error) {
                // console.log(error);
                if (isActive) {
                    setConnectionError(true);
                }
            };
        }

        

       

        return () => {
            isActive = false;

            
        }

    }, [props, ws, token, url, uuid, connectionError, history, setCreateAccount, setModal, setMessage, t])
    
    useEffect(() => {
        
        let timer;

        if (showTimer)  {
            timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        }
        
        return () => clearInterval(timer);
      
    }, [counter, showTimer]);

    useEffect(()=> {
        showTimerRef.current = setTimeout(function() {
            setShowTimer(true);

            timerRef.current = setTimeout(function() {
                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_OFF");
                    setTimeout(function() {
                        ws.current.send("CLOSE");
                        ws.current.onopen = function(e) {};
                        ws.current.onclose = function(e) {};
                        ws.current.close();
                    }, 100);
                }
                setModal(false);
            }, 5000);
        }, 10000);

        return () => {
            setShowTimer(false);
            setCounter(5);
            setConnectionError(false);
            clearTimeout(timerRef.current);
            clearTimeout(showTimerRef.current);
        }
    }, [showTimerRef, timerRef, setModal]);

    
    return (
    <div className={`fixed flex flex-direction-column align-items-center justify-content-center ndef-reader-modal on`}>
        <div className='relative modal-container bg-white box-shadow flex flex-direction-column justify-content-center align-items-center'>
            <div className='content flex flex-direction-row justify-content-center align-items-center full-height'>
                <img src={bgReader} alt={t("NDEFReaderModal:waiting-for-card")} width="286" height="87" />
                <div className='text text-center relative flex flex-direction-column justify-content-center align-items-center full-height'>
               
                    <img src={bgLoader} alt={t("NDEFReaderModal:waiting-for-card")} width="auto" height="auto" />
                    <div className='message bolder-x'>{parse(t("NDEFReaderModal:waiting-for-card"))}</div>

                    {!connectionError && !cardDetected &&
                    <div className='notice bolder'>{parse(t("NDEFReaderModal:place-card"))}</div>
                    }

                    {connectionError && !cardDetected &&
                        <div className='error bolder cl-error1'>{parse(t("NDEFReaderModal:check-connection"))}</div>
                    }

                    {cardDetected &&
                    <div className='success cl-success1 bolder'>{parse(t("NDEFReaderModal:card-detected"))}</div>
                    }

                    {showTimer && 
                        <div className="counter absolute bolder-x">{counter}</div>
                    }
                    
                </div>
            </div>
            
            <button className='cancel absolute' onClick={()=> {
                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_OFF");
                    setTimeout(function() {
                        ws.current.send("CLOSE");
                        ws.current.close();
                    }, 100);
                }

                setModal(false);
                }}>{parse(t("NDEFReaderModal:cancel"))}</button>
            <button className='close absolute' onClick={()=> {
                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_OFF");
                    setTimeout(function() {
                        ws.current.send("CLOSE");
                        ws.current.close();
                    }, 100);
                }

                setModal(false);
                }}>{parse(t("NDEFReaderModal:close"))}</button>
        </div>
    </div>
    )
}

export default withTranslation()(NDEFReaderModal)