import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { replaceComma, replaceDot, formatFullDate } from "../../Helpers";
import bgContestCard from '../../../assets/images/bg-contest-card.svg';
import '../../../assets/css/components/Account/Modals/ContestationModal.css';

const ContestationModal = (props) => {
    const {t} = useTranslation();
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const { data, setData, setContestationModal, contestationModal, setContestableOps } = props;
    const [amountError, setAmountError] = useState("");
    const [explanationError, setExplanationError] = useState("");
    const [connectionError, setConnectionError] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isDoingAction, setIsDoingAction] = useState(false);
    const [currentContest, setCurrentContest] = useState({
        "token": token,
        "idcompte" : data.id,
        "uuidcompte" : data.braceletprincipal,
        "idmvt" : contestationModal.obj.idmvt,
        "montantancien" : Number(contestationModal.obj.montant),
        "montantnouveau" : "",
        "explication" : ""
    });

    const triggerContest = async(obj) => {
        if (obj !== null) {

            await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-contester.awp", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    "Accept": "*/*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Connection": "keep-alive"
                },
                body: JSON.stringify(obj)
            })
            .then(res => res.json())
            .then(
            (result) => {
         
                setIsDoingAction(false);

                if (result.resultat === "ok") {
                    if (result.reponse === "inscription") {
                        setData(result.data);
                        setContestationModal(false);
                    }
                }

                if (result.resultat === "erreur") {

                    switch(result.reponse) {
                        case "Identification compte":
                            setConnectionError(parse(t("ContestationModal:id-uuid-mismatch")));
                        break;
                        case "Pb TOKEN":
                            setConnectionError(parse(t("ContestationModal:token-issue")));
                        break;
                        case "Données manquantes":
                            setConnectionError(parse(t("ContestationModal:missing-data")));
                        break;
                        case "format non respecte":
                            setConnectionError(parse(t("ContestationModal:wrong-data")));
                        break;
                        default:
                            setConnectionError(parse(result.reponse));
                        break;
                    }
                }

            },
            (error) => {
                setIsDoingAction(false);
                setConnectionError(parse(error));
            });
        } else {
            setIsDoingAction(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isDoingAction) {
            setIsDoingAction(true);
                
            let tmpObj = currentContest;

            if (tmpObj.montantnouveau === "" || tmpObj.explication === "") {
                if (tmpObj.montantnouveau === "") {
                    setAmountError(parse(t("ContestationModal:enter-amount")));
                    tmpObj.montantnouveau = "";
                }

                if (tmpObj.explication === "") {
                    setExplanationError(parse(t("ContestationModal:enter-comment")));
                    tmpObj.explication = "";
                }
            } else {

                tmpObj.montantnouveau = Number(replaceComma(tmpObj.montantnouveau));
                
                if (Number(tmpObj.montantancien) < 0) {
                    tmpObj.montantnouveau = -tmpObj.montantnouveau
                }

                setAmountError("");
                setExplanationError("");
                triggerContest(tmpObj);
            }

        }
    }

    const onEnterPress = (e) => {
    if (e.key === "Enter") {
        handleSubmit(e)
    }
    }

    return(
    <div className={`fixed flex flex-direction-column align-items-center justify-content-center contest-card-modal on`}>
        <div className='relative modal-container bg-white box-shadow flex flex-direction-column justify-content-center align-items-center'>
            
   
            <img className="desktop-all bg-contest-card absolute" src={bgContestCard} alt={parse(t("ContestationModal:contestation"))} width="96" height="84" />

            <div className='content full-width flex flex-direction-column justify-content-center align-items-center full-height'>
                <div className="modal-title bolder-x">{parse(t("ContestationModal:contestation"))}</div>
                <div className="modal-subtitle bolder-x">{parse(t("ContestationModal:fill-in-amounts"))}</div>
                <div className="summary relative full-width bg-grey-light flex flex-direction-column flex-wrap align-items-center justify-content-center">
                    <div className='relative summary-head cl-turquoise uppercase bolder'>
                        {parse(t("ContestationModal:contest-for-payment"))}
                    </div>
                    <div className='relative summary-content full-width flex flex-direction-row align-items-center justify-content-between'>
                        <div className='summary-date uppercase cl-turquoise bolder-x'>{parse(t("ContestationModal:date"))} <span className='cl-black normal'>{parse(t("ContestationModal:date-format", {date:formatFullDate(contestationModal.obj.dateheure)}))}</span></div>
                        <div className='summary-amount uppercase cl-turquoise bolder-x'>{parse(t("ContestationModal:amount"))} <span className='cl-black normal uppercase'>{parse(t("ContestationModal:amount-format", {amount:replaceDot(contestationModal.obj.montant), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</span></div>
                        <div className='summary-location uppercase cl-turquoise bolder-x'>{parse(t("ContestationModal:location"))} <span className='cl-black normal'>{contestationModal.obj.nomboutique}</span></div>

                    </div>
                    
                </div>

                <form onSubmit={handleSubmit}>
                 
                    <div className="form-group full-width contest-form text-left">

                        <div className='input'>
                            <p>{parse(t("ContestationModal:new-amount"))}
                                <label htmlFor="montantnouveau" className="group-item flex flex-direction-row text-left normal align-items-start relative">
                                    <span className={`plus${isFocused ? " focus" : ""}`}>+</span> <input min="0" step="0.01" type="number" className={`form-control bolder${isFocused ? " focus" : ""}`} name="montantnouveau" value={currentContest.montantnouveau} id="montantnouveau" onChange={e => {
                                        if(e.target.value === "0") {
                                            setCurrentContest(prevState => ({...prevState, montantnouveau:e.target.value}));
                                        } else {

                                            if (e.target.value === "") {
                                                setAmountError(parse(t("ContestationModal:no-empty")));
                                                setCurrentContest(prevState => ({...prevState, montantnouveau:e.target.value}));
                                            } else {
                                                setCurrentContest(prevState => ({...prevState, montantnouveau:e.target.value}));
                                                setAmountError("");
                                                setExplanationError("");
                                            }
                                            
                                        }
                                    }} onFocus={e => {
                                        setIsFocused(true)
                                    }}

                                    onBlur={e => {
                                        setIsFocused(false)
                                    }} onKeyDown={onEnterPress} /><span className={isFocused ? "focus uppercase" : "uppercase"}>{data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</span><em className="cl-error1 bolder">{amountError}</em>
                                </label>
                            </p>
                        </div>
                    
                        <ul>
                            <li>{parse(t("ContestationModal:condition-1"))}</li>
                            <li>{parse(t("ContestationModal:condition-2"))}</li>
                        </ul>
                        
                        <div className="input">
                            <p>{parse(t("ContestationModal:explanation"))}
                                <label htmlFor="explication" className="group-item flex flex-direction-row text-left normal align-items-center justify-content-center relative">
                                    <input maxLength="80" type="text" 
                                    value={currentContest.explication} 
                                    onKeyDown={onEnterPress}
                                    onChange={e => {

                                        
                                        setCurrentContest(prevState => ({
                                            ...prevState, 
                                            explication:e.target.value
                                        }));

                                        if (e.target.value === "") {
                                            setExplanationError(parse(t("ContestationModal:enter-comment")));
                                        } else {
                                            setConnectionError("");
                                            setAmountError("");
                                            setExplanationError("");
                                        }
                                    }}
                                    
                                    className={`explication`} id="explication" /><em className="cl-error1 bolder">{explanationError}</em>
                                </label>
                            </p>
                        </div>
                    </div>

                    <div className="buttons relative flex flex-direction-row flex-wrap align-items-center justify-content-center">
                        <button className='cancel' onClick={()=> {
                            setContestableOps(prevState => ({
                                ...prevState, 
                                ["contested-"+contestationModal.contested]:false
                            }));
                            setContestationModal(false);
                           
                            }}>{parse(t("ContestationModal:cancel"))}
                        </button>
                        <button className='apply'>{parse(t("ContestationModal:apply"))}</button>
                    </div>
            
                </form>
                
                <div className='error-container relative'>
                    {connectionError !== "" &&
                    <div className='relative cl-error1 bg-error2 flex align-items-center justify-content-center bolder text-transform-none text-center'>{connectionError}</div>
                    }
                </div>

                <button className='close absolute' onClick={()=> {
                setContestableOps(prevState=>({
                    ...prevState,
                    ["contested-"+contestationModal.contested]:false
                }));
                setContestationModal(false);
                }}>{parse(t("ContestationModal:close"))}
                </button>
            </div>
            
            

            
  
        </div>
    </div>
    );
}

export default ContestationModal;