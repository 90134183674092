import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { replaceComma } from "../../Helpers";
import bgEditCard from '../../../assets/images/bg-edit-card.png';
import '../../../assets/css/components/Account/Modals/EditCardModal.css';

// en suspens : reprendre

const EditCardModal = (props) => {
    const {t} = useTranslation();
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const { cid } = useParams();
    const { data, match, setData, setEditCardModal, setReadCardModal } = props;
    const [amountError, setAmountError] = useState("");
    const [connectionError, setConnectionError] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [currCard, setCurrCard] = useState(null);
    const [isDoingAction, setIsDoingAction] = useState(false);

    const [formData, setFormData] = useState({
        idcompte:"",
        uuidcompte:"",
        associeid:"",
        associeuuid:"",
        associetexte:"",
        associelimite: "aucune",
        associemontant: "0"
    });

    const editAuxCard = async() => {
        if (token !== "" && url !== "" && currCard.bracelet !== null && data !== null && formData !== null) {

            await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-modifannexe.awp", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    "Accept": "*/*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Connection": "keep-alive"
                },
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then(
            (result) => {
         
                setIsDoingAction(false);

                if (result.resultat === "ok") {
                    if (result.reponse === "modif") {
                        setData(result.data);
                        setEditCardModal(false);
                        setReadCardModal(false);
                    }
                }

                if (result.resultat === "erreur") {
                    if (result.reponse === "Identification compte") {
                        setConnectionError(parse(t("EditCardModal:id-uuid-mismatch")));
                    }

                    if (result.reponse === "Pb TOKEN ou problème limite non reconnue") {
                        setConnectionError(parse(t("EditCardModal:token-or-limit-unrecognized")));
                    }
                    
                    if (result.reponse === "Données manquantes compte") {
                        setConnectionError(parse(t("EditCardModal:missing-data")));
                    }

                    if (result.reponse === "format non respecte") {
                        setConnectionError(parse(t("AddCardModal:wrong-data")));
                    }

                    
                }

            },
            (error) => {
                setIsDoingAction(false);
                setConnectionError(parse(error));
            });
        } else {
            setIsDoingAction(false);
        }
    };

  
      const handleSubmit = (e) => {
          e.preventDefault();



          if (!isDoingAction) {
            setIsDoingAction(true);
            
            //-> Si le type de limite pas mis à jour
            if (formData.associelimite !== "aucune") {
            
                if (formData.associemontant === "" || Number(formData.associemontant) === 0) {
                    setIsDoingAction(false);
                    setAmountError(parse(t("EditCardModal:enter-amount")));
                } else {
                    setFormData(prevState => ({
                        ...prevState, 
                        associemontant:replaceComma(formData.associemontant)
                    }));
                    setAmountError("");
                    
                    editAuxCard(setIsDoingAction);
                }
                
            } else {
                setAmountError("");
                editAuxCard(setIsDoingAction);
            }
          }
          
          
      }
  
      const onEnterPress = (e) => {
        if (e.key === "Enter") {
          handleSubmit(e)
        }
      }

      useEffect(()=> {
        Object.keys(data.braceletannexes).forEach((i) => {
            let obj = data.braceletannexes[i];
            
            if (Number(match.params.cid) === Number(obj.id)) {
                setCurrCard(obj);
            }
        });

        if (currCard !== null) {
            setFormData(prevState => ({...prevState,
                token: token,
                idcompte: data.id,
                uuidcompte: data.braceletprincipal,
                associeid: currCard.id,
                associeuuid: currCard.bracelet,
                associetexte: currCard.textelogiciel,
                associelimite: currCard.limitetype,
                associemontant: currCard.limitemontant
            }));

            if (currCard.limitetype === "aucune") {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }
      }, [data.braceletannexes, data.braceletprincipal, data.id, match.params.cid, currCard, token]);


    return(
    <div className={`fixed flex flex-direction-column align-items-center justify-content-center edit-card-modal on`}>
        <div className='relative modal-container bg-white box-shadow flex flex-direction-column justify-content-center align-items-center'>
            
   
            <img className="desktop-all bg-edit-card absolute" src={bgEditCard} alt={parse(t("EditCardModal:edit-card"))} width="131" height="460" />

            <div className='content full-width flex flex-direction-column justify-content-center align-items-center full-height'>
                <div className="modal-title bolder-x">{parse(t("EditCardModal:edit-card"))}</div>
 
                <div className='card-info flex flex-direction-row align-items-center justify-content-center'>
                {currCard !== null &&
                <>
                    {currCard.bracelet !== null &&
                        <div className="card-uuid text-center bolder uppercase flex flex-direction-column align-items-center justify-content-center">{parse(t("EditCardModal:uuid"))}<br/><span className="bg-turquoise cl-white bolder-x">{currCard.bracelet}</span><br/>
                        </div>
                    }

                    {currCard.texteutilisateur !== null && currCard.texteutilisateur !== "" &&
                        <div className="web-name text-center bolder uppercase flex flex-direction-column align-items-center justify-content-center">{parse(t("EditCardModal:web-name"))}<br/><span className="bg-turquoise cl-white bolder-x">{parse(currCard.texteutilisateur)}</span><br/>
                        </div>
                    }
                </>
                }
                </div>

                <form onSubmit={handleSubmit}>
                 
                    {Number(cid) !== 0 &&
                        <div className="form-group full-width bg-grey-light limit text-left">

                            <div className='input card-name'>
                                <p>{parse(t("EditCardModal:osmopay-name"))}
                                    <label htmlFor="associetexte" className="group-item flex flex-direction-column text-left normal align-items-start relative">{parse(t("EditCardModal:placeholder"))} 

                                        <input type="text" maxLength="80" className="form-control bolder" name="associetexte" value={formData.associetexte} id="associetexte" onChange={e => {
                                            setFormData(prevState => ({...prevState, associetexte:e.target.value}));
                                        }} onKeyDown={onEnterPress} />
                                    </label>
                                </p>
                            </div>
                        
                            <p>{parse(t("EditCardModal:limit"))}</p>
                            <div className="labels flex flex-direction-row flex-wrap">
                            <label htmlFor="aucune" className="group-item flex flex-direction-row align-items-center relative">
                                <div className="label-styled bg-grey-light relative">
                                    <input type="checkbox" id="aucune" name="aucune" value="aucune" checked={formData.associelimite === "aucune"} onChange={e => {
                                    setAmountError("");
                                    setConnectionError("");
                                    setFormData(prevState => ({
                                        ...prevState, 
                                        associelimite:e.target.value,
                                        associemontant:"0"
                                    }));

                                    setIsDisabled(true)
                                    }} />
                                    <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="aucune"></label>
                                </div>
                                <span>{parse(t("EditCardModal:no-limit"))}</span>
                            </label>

                            <label htmlFor="jour" className="group-item flex flex-direction-row align-items-center relative">
                                <div className="label-styled bg-grey-light relative">
                                <input type="checkbox" id="jour" name="jour" value="jour" checked={formData.associelimite === "jour"} onChange={e => { 
                                    setAmountError("");
                                    setConnectionError("");
                                    
                                    setFormData(prevState => ({
                                        ...prevState, 
                                        associelimite:e.target.value,
                                        associemontant:""
                                    }));
                                    
                                    setIsDisabled(false);
                                }} />

                                    <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="jour"></label>
                                </div>
                                <span>{parse(t("EditCardModal:daily-limit"))}</span>
                            </label>

                            <label htmlFor="sejour" className="group-item flex flex-direction-row align-items-center relative">
                                <div className="label-styled bg-grey-light relative">
                                <input type="checkbox" id="sejour" name="sejour" value="sejour" checked={formData.associelimite === "sejour"} onChange={e => {    
                                    setIsDisabled(false);
                                    setAmountError("");
                                    setConnectionError("");

                                    setFormData(prevState => ({
                                        ...prevState, 
                                        associelimite:e.target.value,
                                        associemontant:""
                                    }));
                                    
                                }} />

                                    <label className="fake-label flex flex-direction-row align-items-center justify-content-center relative" htmlFor="sejour"></label>
                                </div>
                                <span>{parse(t("EditCardModal:stay-limit"))}</span>
                            </label>
                            </div>

                            <div className="input">
                                <p>{parse(t("EditCardModal:limit-amount"))}
                                    <label htmlFor="focus" className="group-item flex flex-direction-row text-left normal align-items-start relative">
                                        <input min="0" step="0.01" type="number" 
                                        value={formData.associemontant} 
                                        onKeyDown={onEnterPress}
                                        onChange={e => {

                                            let filteredVal = e.target.value;

                                            setFormData(prevState => ({
                                                ...prevState, 
                                                associemontant:e.target.value
                                            }));

                                            
                                            setConnectionError("");

                                            if (filteredVal !== "0" && filteredVal !== "") {
                                                setAmountError("");
                                            } else {
                                                setAmountError(parse(t("EditCardModal:enter-amount")));
                                            }
                                        }}

                                        onFocus={e => {
                                            setIsFocused(true)
                                        }}

                                        onBlur={e => {
                                            setIsFocused(false)
                                        }}

                                        disabled={isDisabled} className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")} id="focus" /><span className={(isDisabled ? "disabled" : "") + (isFocused ? " focus" : "")}>{data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</span>
                                        <em className="cl-error1 absolute text-transform-none">{amountError}</em>
                                    </label>
                                </p>
                            </div>
                        </div>
                    }

                    <div className="buttons relative flex flex-direction-row flex-wrap align-items-center justify-content-center">
                        <button className='cancel' onClick={()=> {
                            setEditCardModal(false);
                            }}>{parse(t("EditCardModal:cancel"))}
                        </button>
                        <button className='save'>{parse(t("EditCardModal:save"))}</button>
                    </div>
            
                </form>
                
                <div className='error-container relative'>
                    {connectionError !== "" &&
                    <div className='relative cl-error1 bg-error2 flex align-items-center justify-content-center bolder text-transform-none text-center'>{connectionError}</div>
                    }
                </div>

                <button className='close absolute' onClick={()=> {
                setEditCardModal(false);
                }}>{parse(t("EditCardModal:close"))}
                </button>
            </div>
            
            

            
  
        </div>
    </div>
    );
}

export default EditCardModal;