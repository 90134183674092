import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Switch, Route, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Account from './components/Account/Account';
import NotFound from './components/NotFound';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18n from './i18n/config';
import './assets/css/general/reset.css';
import './assets/css/general/animations.css';
import { toggleRecaptcha } from "./components/Helpers";

export const routes = [

  {
    path: "/login",
    component: Login,
    componentText: "Login"
  },
  {
    path: "/dashboard",
    component: Dashboard,
    componentText: "Dashboard"
  },
  {
    path: "/account",
    component: Account,
    componentText: "Account"
  }
];

const AnimatedSwitch = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("");
  
  useEffect(() => {
    if (location !== displayLocation) {
      if (location.pathname.indexOf("/account") === -1) {
        setTransitionStage("fadeIn");
      }
      
    }
  }, [location, displayLocation]);



  let currentComponent = "";

  Object.keys(routes).forEach((i) => {
    if (location.pathname.indexOf(routes[i].path) > -1) {
      currentComponent = routes[i].componentText
    }
  })
  const nodeRef = React.useRef(null)
  
  return (

      <div ref={nodeRef} className={`component ${transitionStage} ${currentComponent.toLowerCase()}`} onAnimationEnd={() => {
          setTransitionStage("");
          setDisplayLocation(location);
      }}>

      <div className="mobile-notice-container fixed bg-black bg-grey-dark">
        <div className="mobile-notice relative bg-grey-light box-shadow text-center">{parse(t("Main:not-available-on-mobile"))}</div>
      </div>

      <Switch location={(location)}>
       
        <Route path="/login" render={(props) => {

            let loggedIn = Boolean(localStorage.getItem('r-loggedIn'))

            if (loggedIn) {
              toggleRecaptcha(false);
              
              return(
                <Redirect to="/dashboard" />
              )
              
            } else {
              
              toggleRecaptcha(true);
              return(
                <Login {...props} />
              )
            }
            
          }
        } />

        <Route path="/dashboard" render={(props) => {

            let loggedIn = Boolean(localStorage.getItem('r-loggedIn'))
            if (loggedIn) {
              
              toggleRecaptcha(false);
              return(
                <Dashboard location={location} displayLocation={displayLocation} {...props} />
              )
              
            } else {
              toggleRecaptcha(true);
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />

        <Route exact path="/account/:id" render={(props) => {
            let loggedIn = Boolean(localStorage.getItem('r-loggedIn'))

            if (loggedIn) {
              toggleRecaptcha(false);
              return(
                <Account location={location} displayLocation={displayLocation} id={props.match.params.id} {...props} />
              )
            } else {
              
              toggleRecaptcha(true);
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />


        <Route exact path="/account/:id/:cid" render={(props) => {
            let loggedIn = Boolean(localStorage.getItem('r-loggedIn'))

            if (loggedIn) {
              return(
                <Account location={location} displayLocation={displayLocation} id={props.match.params.id} cid={props.match.params.cid} {...props} />
              )
            } else {
              
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />

        <Route exact path="/account/:id/(manage-cards|reload|refund|info)" render={(props) => {
            let loggedIn = Boolean(localStorage.getItem('r-loggedIn'));

            if (loggedIn) {
              return(
                <Account location={location} displayLocation={displayLocation} id={props.match.params.id} {...props} />
              )
            } else {
              return(
                <Redirect to="/login" />
              )
            }

          }
        } />

        
        <Redirect strict exact from="/" to="/login" />

        <Route path="*" render={(props) => {
            toggleRecaptcha(false);
            return(
                <NotFound {...props}/>
            )
          }
        } />

        {Object.keys(routes).forEach((i) => {
          if (location.pathname.indexOf(routes[i].path) > -1) {
            currentComponent = routes[i].componentText;
          }
        })}
      </Switch>
      
    </div>
    
  )
};


function App () {

  useEffect(() => {
    
    const updateDimensions = () => {
      const width = window.innerWidth
      if (width > 1024) {
        document.body.classList.add("desktop")
      } else {
        document.body.classList.remove("desktop")
      }
    }

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  


  return (
    <div className="App">
      <Router>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          language={i18n.language}
          scriptProps={{
            async: true,
            id: "google-recaptcha-v3",
            defer: true,
            appendTo: 'head',
            nonce: undefined
          }}
        >
          <AnimatedSwitch/>
        </GoogleReCaptchaProvider>
      </Router>
    </div>
  );
}

export default withTranslation()(App);
