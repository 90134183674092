import React, { useState } from "react";
import parse from 'html-react-parser';
import { withTranslation, useTranslation } from 'react-i18next';
import '../../assets/css/components/Dashboard/SearchBox.css';

const SearchBox = (props) => {
    const {t} = useTranslation();
    const [filterCI, setFilterCI] = useState('')
    const { setFilter } = props;
    return (
    <div className="search-box bg-turquoise box-shadow full-width">
        <div className="sb-head cl-white uppercase bolder-x text-center">{parse(t('SearchBox:search-in-active-accounts'))}</div>

        <input type="text" placeholder={t("SearchBox:active-account-name")} className='search full-width' name='search' id='search' value={filterCI} onChange={event => {
            setFilter(event.target.value.toLowerCase())
            setFilterCI(event.target.value)
        }}/>
    </div>
    )
}

export default withTranslation()(SearchBox);