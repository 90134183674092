import { NavLink } from "react-router-dom";
import { toggleDropDown, clearLocalStorage } from "./Helpers";
import { useTranslation, withTranslation } from "react-i18next";
import '../assets/css/general/dropdown.css';
import '../assets/css/components/UserMenu.css';

const UserMenu = (props) => {
    const { t } = useTranslation();
    const { data, history } = props;

    return (
        <>
        {data !== null &&
   
            <ul className='dropdown-container user-menu box-shadow absolute'>
                <li className="current" onClick={(e) => {
                    toggleDropDown(".user-menu")
                }}>{localStorage.getItem("r-etablissement")}
                    <ul className="dropdown">
                        <li>
                            <NavLink onClick={(e)=> {
                                e.preventDefault()
                                clearLocalStorage();
                                localStorage.removeItem('r-submitTimeLimit')
                                localStorage.removeItem('r-trialLimit');
                                history.push({
                                    pathname:"/login"
                                })
                            }} to="/login">{t("UserMenu:logout")}</NavLink>
                        </li>
                   
                    </ul>
                </li>
            </ul>
        
        }

        </>
    )
}

export default withTranslation()(UserMenu)