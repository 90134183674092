import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import bgReader from '../../../assets/images/reader.png';
import bgExists from '../../../assets/images/bg-card-exists.png';
import bgLoader from '../../../assets/images/loader-bracelet.svg';
import '../../../assets/css/components/Account/Modals/ReadCardModal.css';
import { replaceMainCard } from '../../Helpers';

const ReadCardModal = (props) => {
    const {t} = useTranslation();
    const timerRef = useRef(null);
    const showTimerRef = useRef(null)
    const [counter, setCounter] = useState(5);
    const [showTimer, setShowTimer] = useState(false);
    const { data, history, setUUID, setReadCardModal, readCardModal, setAddCardModal, addCardModal, replace, replaceAnnexe, setReplace, setReplaceAnnexe, setData, idassocie, uuidassocie, setMessage } = props;
    const ws = useRef(null);
    const uuid = useRef(null);
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const [connectionError, setConnectionError] = useState(false);
    const [cardDetected, setCardDetected] = useState(false);
    const [exists, setExists] = useState(false);
    const [replaced, setReplaced] = useState(false);

    useEffect(()=> {

        if (!exists && !connectionError) {
            const { REACT_APP_LOCALSERVER_URL } = process.env;
            ws.current = new WebSocket(REACT_APP_LOCALSERVER_URL);
            
            const testUUID = async() => {
                if (token !== "" && url !== "" && uuid.current !== null) {
                    await fetch(url + "OSMOPAYVII_WEB/FR/apiaccueil-testuuid.awp", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            "Accept": "*/*",
                            "Accept-Encoding": "gzip, deflate, br",
                            "Connection": "keep-alive"
                        },
                        body: JSON.stringify({
                            "token": token,
                            "uuid": uuid.current,
                        })
                    })
                    .then(res => res.json())
                    .then(
                    (result) => {

                        if (result.reponse === "libre") {
                            if(replace || replaceAnnexe) {
                                let api = "remplacerprincipal";
                                let tmpObj = {
                                    token: token, 
                                    idcompte: data.id, 
                                    uuidcompte: data.braceletprincipal, 
                                    uuidnouveau: uuid.current,
                                    explication: ""
                                }

                                if (replaceAnnexe) {
                                    api = "remplacerannexe";
                                    tmpObj.idassocie = idassocie;
                                    tmpObj.uuidassocie = uuidassocie;
                                }

                                replaceMainCard(api, url, tmpObj).then((result) => {
                                    if (result.resultat === "ok") {
                                        if (result.reponse === "remplacement") {
                                            if (result.data !== data) {
                                                setData(result.data);
                                                setMessage(parse(t("ReadCardModal:card-replaced")));
                                            }
                                        }
                                    }

                                    if (result.resultat === "erreur") {
                                        if (result.reponse === "Pb TOKEN") {
                                            setMessage(parse(t("ReadCardModal:token-issue")));
                                        }
                                        
                                        if (result.reponse === "identification compte") {
                                            setMessage(parse(t("ReadCardModal:id-uuid-mismatch")));
                                        }

                                        if (result.reponse === "Bracelet non trouvé, données incohérentes") {
                                            setMessage(parse(t("ReadCardModal:wrong-data")));
                                        }
                                    }

                                });
                                setReadCardModal(false);
                            } else {
                                setUUID(uuid.current);
                                setReadCardModal(false);
                                setAddCardModal(true);
                            }
                        }
    
                        if (result.reponse === "existe") {
                            if(replace || replaceAnnexe) {
                                setExists(true);
                            } else {
                                
                                if (Number(result.data.id) === Number(data.id)) {
        
                                    let bracelet = "";
                                    let replaced = false;
                                    Object.keys(result.data.braceletannexes).forEach((i) => {
                                        let obj = result.data.braceletannexes[i];
                                        
                                        if (obj.bracelet === uuid.current) {
                                            bracelet = "/" + obj.id;
                                            if(obj.textelogiciel === "ancien bracelet principal" || obj.remplaced) {
                                                replaced = obj.remplaced;
                                            }
                                        }
                                    });

                                    if (replaced) {
                                        setReplaced(true);
                                    } else {
                                        if (bracelet === "") {
                                            bracelet = "/0";
                                        }
                                        
                                        history.push({
                                            pathname: "/account/" + data.id + bracelet,
                                        });
    
                                        setReadCardModal(false);
                                    }
                                    
                                    
                                } else {
                                    setExists(true);
                                }
                            }
                        }
                        
                    },
                    (error) => {
                        setMessage(error)
                    });
                }
            };
    
            ws.current.onmessage = function(event) {
            
                let serialNum = JSON.parse(event.data).rfid;
    
                if(serialNum !== "") {
                    setCardDetected(true);
                    uuid.current = serialNum;

                    if (ws.current.readyState === 1) {
                        ws.current.send("RFID_OFF");
                        setTimeout(function() {
                            ws.current.send("CLOSE");
                            ws.current.onopen = function(e) {};
                            ws.current.onclose = function(e) {};
                            ws.current.close();

                            if (uuid.current !== null) {
                                testUUID();
                            }
                        }, 100);
                    }

                   
                }
            
            };
    
            ws.current.onclose = function(event) {
                
                if (event.wasClean) {
                    // console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                    if (event.code === 1006) {
                        //console.log(event);
                    }
                    
                }
            };
    
            ws.current.onopen = function(e) {
                // console.log("[open] Connection established");
                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_ON");
                }
            };
    
            ws.current.onerror = function(error) {
                if (readCardModal) {
                    setConnectionError(true);
                }
            };
        }

        return () => {

            if (replace) {
                setReplace(false);
            }

            if (replaceAnnexe) {
                setReplaceAnnexe(false);
            }

        }

    }, [data, history, setCardDetected, setConnectionError, setMessage, setAddCardModal, addCardModal, readCardModal, setReadCardModal, setUUID, ws, token, url, uuid, connectionError, exists, data.braceletprincipal, replace, replaceAnnexe, setData, t, idassocie, uuidassocie, setReplace, setReplaceAnnexe])
        
        useEffect(() => {
            
            let timer;

            if (showTimer)  {
                timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            }
            
            return () => clearInterval(timer);
        
        }, [counter, showTimer]);

        useEffect(()=> {
            if (!exists) {
                showTimerRef.current = setTimeout(function() {
                    setShowTimer(true);

                    timerRef.current = setTimeout(function() {
                        if (ws.current.readyState === 1) {
                            ws.current.send("RFID_OFF");
                            setTimeout(function() {
                                ws.current.send("CLOSE");
                                ws.current.onopen = function(e) {};
                                ws.current.onclose = function(e) {};
                                ws.current.close();
                            }, 100);
                        }
                        setReadCardModal(false);

                        
                    }, 5000);
                }, 10000);
            } else {

                if (ws.current.readyState === 1) {
                    ws.current.send("RFID_OFF");
                    setTimeout(function() {
                        ws.current.send("CLOSE");
                        ws.current.onopen = function(e) {};
                        ws.current.onclose = function(e) {};
                        ws.current.close();
                    }, 100);
                }
            }

            return () => {
                setShowTimer(false);
                setCounter(5);
                setConnectionError(false);
                clearTimeout(timerRef.current);
                clearTimeout(showTimerRef.current);
            }
            
        }, [setReadCardModal, showTimerRef, timerRef, exists]);
        


    return (
        <div className={`fixed flex flex-direction-column align-items-center justify-content-center read-card-modal on`}>
            <div className={`relative modal-container bg-white box-shadow flex flex-direction-column justify-content-center align-items-center${exists ? " exists" : ""}`}>
                <div className='content flex flex-direction-row justify-content-center align-items-center full-height'>

                {exists &&
                    <>
                        <img className="bg-reader absolute" src={bgExists} alt={t("ReadCardModal:card-exists")} width="231" height="123" />
                        <div className='text text-center relative flex flex-direction-column justify-content-center align-items-center full-height'>
                            {(replace || replaceAnnexe) &&
                                <div className='message bolder-x'>{parse(t("ReadCardModal:already-exists-replace"))}</div>
                            }
                            {(!replace && !replaceAnnexe) &&
                                <div className='message bolder-x'>{parse(t("ReadCardModal:already-exists"))}</div>
                            }

                            <div className='notice'>{parse(t("ReadCardModal:place-another-card"))}</div>
                            <button className='ok' onClick={(e)=> {
                                setReadCardModal(false);
                                }}>{parse(t("ReadCardModal:ok"))}</button>
                        </div>
                    </>
                    }

                    {replaced &&
                    <>
                        <img className="bg-reader absolute" src={bgExists} alt={t("ReadCardModal:card-exists")} width="231" height="123" />
                        <div className='text text-center relative flex flex-direction-column justify-content-center align-items-center full-height'>
                            <div className='message bolder-x'>{parse(t("ReadCardModal:replaced-card"))}</div>
                           
                            <div className='notice'>{parse(t("ReadCardModal:place-another-card"))}</div>
                            <button className='ok' onClick={(e)=> {
                                setReplaced(false);
                                setReadCardModal(false);
                                }}>{parse(t("ReadCardModal:ok"))}</button>
                        </div>
                    </>
                    }

                    {!exists && !replaced &&
                    <>
                    <img src={bgReader} alt={t("ReadCardModal:waiting-for-card")} width="286" height="87" />
                    <div className='text text-center relative flex flex-direction-column justify-content-center align-items-center full-height'>
                        <img src={bgLoader} alt={t("ReadCardModal:waiting-for-card")} width="auto" height="auto" />
                        <div className='message bolder-x'>{parse(t("ReadCardModal:waiting-for-card"))}</div>
    
                        {!connectionError && !cardDetected &&
                        <div className='notice bolder'>{parse(t("ReadCardModal:place-card"))}</div>
                        }
    
                        {connectionError && !cardDetected &&
                            <div className='error bolder cl-error1'>{parse(t("ReadCardModal:check-connection"))}</div>
                        }
    
                        {cardDetected &&
                        <div className='success cl-success1 bolder'>{parse(t("ReadCardModal:card-detected"))}</div>
                        }
    
                        {showTimer && 
                            <div className="counter absolute bolder-x">{counter}</div>
                        }
                    </div>
                    </>
                    }
                </div>
                
                

                {!exists && !replaced && 
                    <button className='cancel absolute' onClick={(e)=> {
                    if (ws.current.readyState === 1) {
                        ws.current.send("RFID_OFF");
                        setTimeout(function() {
                            ws.current.send("CLOSE");
                            ws.current.close();
                        }, 100);
                    }
                    
                    setReadCardModal(false);
                    }}>{parse(t("ReadCardModal:cancel"))}</button>
                }

                <button className='close absolute' onClick={(e)=> {
                    
                    if (ws.current.readyState === 1) {
                        ws.current.send("RFID_OFF");
                        setTimeout(function() {
                            ws.current.send("CLOSE");
                            ws.current.close();
                        }, 100);
                    }
                    
                    setReadCardModal(false);
                    }}>{parse(t("ReadCardModal:close"))}</button>
            </div>
    
            
        </div>
    )
}

export default withTranslation()(ReadCardModal)