
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { loadPaymentTypes, refundAccount, replaceComma, replaceDot, sendOperations, closeAccount, refundsList, formatDate } from "../Helpers";
import bgDeleteCard from '../../assets/images/delete-card.png';
import '../../assets/css/components/Account/Close.css';

const Close = (props) => {
    const {i18n, t} = useTranslation();
    const {data, setData, history } = props;
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const canRefund = localStorage.getItem("r-fctrembourser") === 'true';
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [opID, setOpID] = useState(null);
    const [amountError, setAmountError] = useState("");
    const [modeError, setModeError] = useState("");
    const [message, setMessage] = useState(null);
    const [accountClosed, setAccountClosed] = useState(false);
    const [refList, setRefList] = useState(null);
    const [isDoingAction, setIsDoingAction] = useState(false);
    const [closeAccountPopup, setCloseAccountPopup] = useState(false);
    const [formData, setFormData] = useState({
        token:token,
        idcompte:data.id,
        uuidcompte:data.braceletprincipal,
        mode:"",
        montant:data.maxremboursable || 0
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!isDoingAction) {
            setIsDoingAction(true);
        
            let tmpObj = formData;
            tmpObj.montant = -Number(replaceComma(tmpObj.montant));

            if (tmpObj.montant === 0 || tmpObj.mode === "") {
                if (tmpObj.montant === 0) {
                    setAmountError(parse(t("Close:amount-not-selected")));
                    setFormData(prevState => ({
                        ...prevState,
                        montant:""
                    }));
                } else {
                    setAmountError("");
                }

                if (tmpObj.mode === "") {
                    setModeError(parse(t("Close:mode-not-selected")));
                    setFormData(prevState => ({
                        ...prevState,
                        montant:Math.abs(Number(formData.montant))
                    }));
                } else {
                    setModeError("");
                }

                setIsDoingAction(false);
            } else {
                refundAccount(url, tmpObj).then((result) => {
                    if (result.resultat === "ok") {
                        setModeError("");
                        setAmountError("");
                        setOpID(result.data.idmouvement);
                        setData(result.data);
                        setFormData(prevState => ({
                            ...prevState,
                            montant:"",
                            mode:""
                        }));
                        setMessage(parse(t("Close:loading-performed")));
                    }
                
                    if (result.resultat === "erreur") {

                        setFormData(prevState => ({
                            ...prevState,
                            montant:""
                        }));

                        if (result.reponse === "Pb TOKEN") {
                            setMessage(parse(t("Close:token-unrecognized")));
                        }

                        if (result.reponse === "Identification compte") {
                            setMessage(parse(t("Close:id-uuid-mismatch")));
                        }

                        if (result.reponse === "Mode non autorisé") {
                            setMessage(parse(t("Close:unauthorized-mode")));
                        }

                        if (result.reponse === "Montant incohérent") {
                            setMessage(parse(t("Close:amount-issue")));
                        }

                        if (result.reponse === "Données manquantes") {
                            setMessage(parse(t("Close:missing-data")));
                        }

                        if (result.reponse === "Remboursement supérieur au solde") {
                            setMessage(parse(t("Close:insufficient-balance")));
                        }
                    }
                    
                    setIsDoingAction(false);
                });
            }
        }
        
    };
    

    useEffect(() => {
        let isActive = true;

        if (canRefund) {
            loadPaymentTypes(url, token).then((result) => {
                if (isActive) {
                    if (result.resultat === "ok") {
                        setPaymentTypes(result.data);
                    }
        
                    if (result.resultat === "erreur") {
                        if (result.reponse === "Pb TOKEN") {
                            setMessage(parse(t("Close:token-issue")));
                        }
                    }
                }
                
            });
        }

        if (isActive) {

            refundsList(url, token, data.id, data.braceletprincipal).then((result) => {
                if (isActive) {
                    if (result.resultat === "ok") {
    
                        if (result.data.demandes.length > 0) {
                            setRefList(result.data.demandes);
                        }
                    }
        
                    if (result.resultat === "erreur") {
                        if (result.reponse === "Pb TOKEN") {
                            setMessage(parse(t("Close:token-issue")));
                        }
    
                        if (result.reponse === "Erreur de blocage") {
                            setMessage(parse(t("Close:blocking-error")));
                        }
    
                        if (result.reponse === "dejà remplace") {
                            setMessage(parse(t("Close:already-replaced")));
                        }
                        
                        if (result.reponse === "identification compte" || result.reponse === "identification annexes") {
                            setMessage(parse(t("Close:id-uuid-mismatch")));
                        }
    
                        if (result.reponse === "Données manquantes") {
                            setMessage(parse(t("Close:missing-data")));
                        }
                    }
                }
                
            });
        }

        return () => {
            setAccountClosed(false);
            isActive = false;
        }
    }, [data, token, url, t, canRefund]);

    return(
        <>
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{parse(t('Close:title'))}</title>
                <meta name="description" content={parse(t("Close:desc"))} />
            </Helmet>
        </HelmetProvider>
        {data !== null &&
            <div className="refund relative ">

                <div className='header'>
                    <div className='panel-title cloture'>{parse(t("Close:close-account"))}</div>
                </div> 

                <div className='refund-container relative flex flex-direction-column align-items-evenly justify-content-start'>

                    {refList !== null &&
                    <>
                        <div className="relative title bolder-x cl-turquoise">{parse(t("Close:history"))}</div>

                        <div className='refunds relative'>
                            <div className="list table full-width">
                                <div className="table-heading">
                                    <div className="table-row cl-grey-dark bold">
                                        <div className="row-1">{parse(t("Close:state"))}</div>
                                        <div className="row-2">{parse(t("Close:request-date"))}</div>
                                        <div className="row-3">{parse(t("Close:message"))}</div>
                                        <div className="row-4">{parse(t("Close:answer"))}</div>
                                    </div>
                                </div>

                                <div className='table-body'>
                                {Object(refList).map((obj, i)=> {

                                    let date = obj.webdatedemande?.substring(0, 8);
                                    let hh = obj.webdatedemande?.substring(8, 10);
                                    let mm = obj.webdatedemande?.substring(10, 12);

                                    return (
                                        <div className='refund table-row item' key={i}>
                                            <div className="row-1">{obj.status}</div>
                                            <div className="row-2"><span>{formatDate(date)}<br/>{parse(t("Close:time-format", {hh:hh, mm:mm}))}</span></div>
                                            <div className="row-3">{obj.message}</div>
                                            <div className="row-4">{obj.webreponse}</div>
                                        </div>
                                    )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </>
                    }

                    <div className="relative title bolder-x cl-turquoise">{parse(t("Close:end-account"))}</div>
                    
                    <div className='refundbox-container relative flex flex-direction-column align-items-center justify-content-evenly bg-grey-light'>
                        {canRefund &&
                        <div className='refund-head full-width flex flex-direction-row justify-content-between'>
                            <div className='max-refund cl-error1 flex flex-direction-row align-items-center'>{parse(t("Close:max-refundable-amount", {maxremboursable:replaceDot(data.maxremboursable || 0), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</div>
                            {opID !== null &&
                                <button className='send-operations' onClick={(e)=> {
                                    let tmpObj = {
                                        "token": token,
                                        "id" : data.id,
                                        "uuid" : data.braceletprincipal
                                    }
                                    sendOperations(url, tmpObj);
                                    setMessage(parse(t("Close:operations-sent")));
                                    setOpID(null);
                                }}>{parse(t("Close:send-receipt"))}</button>
                            }
                        </div>
                        }
                        
                        <div className='refundbox relative flex flex-direction-row align-items-end justify-content-evenly'>
                            
                        {canRefund &&
                        <>
                            <div className='relative part-1'>
                                <span className='relative input-title bolder-x'>{parse(t("Close:choose-payment-method"))}</span>
                                <div className="input relative">
                                    <p>
                                        <label className='relative label group-item flex flex-direction-row text-left normal align-items-start relative' htmlFor='payment-type'>
                                            <select name="payment-type" id="payment-type" value={formData.mode} onChange={(e)=> {
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    mode:e.target.value
                                                }));

                                                setModeError("");
                                            }}>
                                            <option value="" disabled>{parse(t("Close:select-payment-method"))}</option>
                                            {paymentTypes !== null && Object(paymentTypes).map((obj, i)=> {
                                                return (
                                                    <option key={i} value={obj.code}>{obj.designation}</option>
                                                )
                                            })}
                                            </select>
                                        </label>
                                    </p>
                                </div>
                                <em className='cl-error1 bolder-x absolute'>{modeError}</em>
                            </div>

                            <div className='relative part-2'>
                                <span className='relative input-title bolder-x'>{parse(t("Close:enter-amount"))}</span>
                                <div className="input relative">
                                    <p>
                                        <label className='relative label group-item flex flex-direction-row text-left normal align-items-start relative' htmlFor='amount'>
                                            <input min="0" step="0.01" type="number" name="amount" id="amount" value={formData.montant} onChange={(e)=>{
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    montant:e.target.value
                                                }));

                                                if (e.target.value !== "" && Number(e.target.value) > 0) {
                                                    setAmountError("");
                                                }
                                            }}/><span>{data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</span>
                                        </label>
                                    </p>
                                </div>
                                <em className='cl-error1 bolder-x absolute'>{amountError}</em>
                            </div>

                            <div className="part-3">
                                <button className='refund-button bolder-x uppercase' onClick={handleSubmit}>{parse(t("Close:refund"))}</button>
                            </div>
                        </>
                        }

                        {!canRefund &&
                            <div className='no-reload flex flex-direction-column text-center'>
                                <div className='no-reload-title bolder-x'>{parse(t("Close:client-info"))}</div>
                                <div className='no-reload-content'>{parse(t("Close:client-info-details"))}</div>
                            </div>
                        }

                        </div>

                        
                    </div>


                    <div className="relative title bolder-x cl-turquoise">{parse(t("Close:close-account"))}</div>

                    <div className="closing-account flex flex-direction-column align-items-center justify-content-center">
                     
                        <div className='text text-center'>{parse(t("Close:closing-text"))}</div>
                        <button className="closing-button uppercase bolder-x" onClick={(e) => {
                            setCloseAccountPopup(true);
                        }}>{parse(t("Close:close"))}</button>
                    </div>

                </div>    
                
            </div>

        }

        {closeAccountPopup && 
            <div className={`close-account-popup on full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}>

                <div className="message-container relative bg-white box-shadow flex flex-direction-column align-items-center justify-content-center">
                    
                    <div className='close-account-message flex flex-direction-row align-items-center justify-content-center'>
                        <img className="bg-delete-card" src={bgDeleteCard} alt={parse(t("Close:delete-card"))} width="107" height="197" />
                        <div className='close-message'>{parse(t("Close:confirm-close"))}</div>
                    </div>
                    
                    <div className='buttons relative'>
                        <button className="cancel uppercase bolder-x" onClick={(e) => {
                            if(accountClosed) {
                                history.push({
                                    pathname: "/dashboard"
                                });
                            } else {
                                setCloseAccountPopup(false);
                            }
                        }}>{parse(t("Close:cancel"))}</button>

                        <button className='close-account uppercase bolder-x' onClick={()=>{
                            if (!isDoingAction) {
                                setIsDoingAction(true);

                                let tmpObj = {
                                    token: token,
                                    idcompte: data.id,
                                    uuidcompte: data.braceletprincipal
                                };

                                closeAccount(url, tmpObj).then((result) => {
                                    setIsDoingAction(false);

                                    if (result.resultat === "ok") {
                                        setAccountClosed(true);
                                        setMessage(parse(t("Close:account-closed")));
                                        setTimeout(function() {

                                            history.push({
                                                pathname: "/dashboard"
                                            });
                                        }, 2000);
                                    }
                                
                                    if (result.resultat === "erreur") {
                                        if (result.reponse === "Pb TOKEN") {
                                            setMessage(parse(t("Close:token-unrecognized")));
                                        }

                                        if (result.reponse === "Identification compte") {
                                            setMessage(parse(t("Close:id-uuid-mismatch")));
                                        }

                                        if (result.reponse === "Données manquantes") {
                                            setMessage(parse(t("Close:missing-data")));
                                        }
                                    }
                                    
                                });
                            }
                        }}>{parse(t("Close:validate"))}</button>
                    </div>
                    
                </div>
            </div>
        }

        {message !== null && 
            <div className={`message-popup${message?.text !== null ? " on" : ""} full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}><div className="message-container relative bg-white box-shadow"><button className="close absolute" onClick={(e) => {
                if(accountClosed) {
                    history.push({
                        pathname: "/dashboard"
                    });
                } else {
                    setMessage(null);
                }
            }}></button><div className="message">{message}</div></div></div>
        }
        </>
    );

      
}

export default Close;