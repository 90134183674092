import React, { useState } from 'react';
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { todayDate, replaceDot, blockAccount, unblockAccount } from "../Helpers";
import '../../assets/css/components/Account/AccountInfo.css';

const AccountInfo = (props) => {
    const { id } = useParams();
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const canUnblock = localStorage.getItem("r-fctdebloquer") === 'true';
    const { t } = useTranslation();
    const { data } = props;
    const [ message, setMessage ] = useState("");
    const [ isDoingAction, setIsDoingAction ] = useState(false);

    if (data !== null) {
        return(
            <div className={`account-balance flex flex-direction-column align-items-center justify-content-evenly ${(data.bloqueetat) ? 'blocked' : ''}`}>
                <div className="name bold cl-turquoise text-center">{data.nom}</div>
                {data.bloqueetat &&
                <div className="blocked-notice cl-white bg-grey-dark bolder text-center">{parse(t("AccountInfo:blocked-account"))}</div>
                }
                <div className="caption bold">{parse(t("AccountInfo:account-balance"))}<br/>
                <span className="data normal">{parse(t('AccountInfo:account-balance-date', {date:todayDate()}))}</span></div>
                
                <div className="balance bolder-x uppercase">{parse(t('AccountInfo:account-balance-number', {number:replaceDot(data.solde), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : ""}))}</div>

                {data.bloqueetat && canUnblock &&
                    <button className="unblock bg-yellow bolder" onClick={()=> {
                        
                        
                        if (!isDoingAction) {
                            setIsDoingAction(true);

                            let obj = {
                                token: token,
                                idprincipal: id,
                                uuidprincipal: data.braceletprincipal,
                                motif: ""
                            };

                            unblockAccount(url, obj).then((result) => {
                                setIsDoingAction(false);

                                if (result.resultat === "ok") {
                                    window.location.reload();
                                }

                                if (result.resultat === "erreur") {

                                    if (result.reponse === "Compte non bloqué") {
                                        setMessage(parse(t("AccountInfo:account-not-blocked")));
                                    }

                                    if (result.reponse === "Pb TOKEN") {
                                        setMessage(parse(t("AccountInfo:token-issue")));
                                    }
                                    
                                    if (result.reponse === "Données manquantes") {
                                        setMessage(parse(t("AccountInfo:missing-data")));
                                    }

                                    if (result.reponse === "Bracelet non trouvée, données incohérentes") {
                                        setMessage(parse(t("AccountInfo:wrong-data")));
                                    }
                                }
                                
                               

                            });
                        }
                       

                    }}>{parse(t("AccountInfo:unblock-account"))}</button>
                }

                {!data.bloqueetat &&
                <>
                    <button className="block bg-yellow bolder" onClick={()=> {

                        if (!isDoingAction) {
                            setIsDoingAction(true);
                            let obj = {
                                token: token,
                                idprincipal: id,
                                uuidprincipal: data.braceletprincipal,
                                motif: ""
                            };

                            blockAccount(url, obj).then((result) => {
                                setIsDoingAction(false);
                                
                                if (result.resultat === "ok") {
                                    window.location.reload();
                                }

                                if (result.resultat === "erreur") {

                                    if (result.reponse === "Compte déjà bloqué") {
                                        setMessage(parse(t("AccountInfo:account-already-blocked")));
                                    }

                                    if (result.reponse === "Pb TOKEN") {
                                        setMessage(parse(t("AccountInfo:token-issue")));
                                    }
                                    
                                    if (result.reponse === "Données manquantes") {
                                        setMessage(parse(t("AccountInfo:missing-data")));
                                    }

                                    if (result.reponse === "Bracelet non trouvée, données incohérentes") {
                                        setMessage(parse(t("AccountInfo:wrong-data")));
                                    }
                                }

                                
                            });
                        }

                    }}>{parse(t("AccountInfo:block-account"))}</button>
                    
                </>
                }
                <div className="message cl-error1">{message}</div>
            </div>
        )
    } else {
        return(
            null
        )
    }
   
}

export default AccountInfo