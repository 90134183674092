import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import { HelmetProvider, Helmet } from "react-helmet-async";
import parse from 'html-react-parser';
import { createAccount } from "../../Helpers";
import ClientList from './ClientList';
import bgUuid from '../../../assets/images/bg-uuid.png';
import '../../../assets/css/components/Dashboard/AccountCreation/AccountCreation.css';

const AccountCreation = (props) => {
    const {i18n, t} = useTranslation();
    const {uuid} = useParams();
    const {data, token, setCreateAccount, history, url} = props;
    const [filterClients, setFilterClients] = useState("");
    const [filterClientsCI, setFilterClientsCI] = useState("");
    const [clientsList, setClientsList] = useState(false);
    const [hide, setHide] = useState(false);
    const [present, setPresent] = useState(true);
    const [message, setMessage] = useState(null);
    const [creationLaunched, setCreationLaunched] = useState(false);

    const [fields, setFields] = useState({
        token: token,
        uuid: uuid,
        idclient: '',
        nom: '',
        prenom: '',
        tel: '',
        email: '',
        montantdecouvert: '',
        datedebut: '',
        datefin: '',
        hebergement: '',
        nbparticipants: ''
    });

    const [error, setError] = useState({
        prenom:false, 
        nom: false, 
        email:false, 
        emailWrong:false
    })

    const errors = {
        prenom: parse(t('AccountCreation:fill-in-prenom')),
        nom: parse(t('AccountCreation:fill-in-nom')),
        email: parse(t('AccountCreation:fill-in-email')),
        emailWrong: parse(t('AccountCreation:invalid-email'))
    };

    const handleOnChange = event => {
        const { name, value } = event.target;
        setFields(prevState => {

            if (value !== "") {
                setError(prevErrors => ({...prevErrors, [name]:false}));

                if (name === "email") {
                    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        setError(prevState => ({...prevState, emailWrong:true}));
                    } else {
                        setError(prevState => ({...prevState, emailWrong:false}));
                    }
                }
                
            } else {
                setError(prevErrors => ({...prevErrors, [name]:true}));

                if (name === "email") {
                    setError(prevErrors => ({...prevErrors, emailWrong:false}));
                }
            }

            return ({ ...prevState, [name]: value })
        });
    };

    const submitForm = event => {
        event.preventDefault();

        if (!creationLaunched) {
            
            setCreationLaunched(true);
        
            let hasError = false;
            Object.keys(errors).forEach((field, i) => {

                setError(prevState => ({...prevState, [field]:false}));

                if (fields[field] === "") {
                    hasError = true;
                    setError(prevState => ({...prevState, [field]:true}));
                }

                if (field === "emailWrong" && fields.email !== "") {
                    if (!fields.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        hasError = true;
                        setError(prevState => ({...prevState, [field]:true}));
                    } else {
                        setError(prevState => ({...prevState, [field]:false}));
                    }
                }
                
            });

            
            if (!hasError) {
            
                if (fields !== null) {
                    let tmpObj = fields;

                    if (tmpObj.datefin !== "" || tmpObj.datefin !== undefined) {
                        tmpObj.datefin = tmpObj.datefin?.toString().replace(/-/gi, "");
                    }
        
                    if (tmpObj.montantdecouvert === undefined || tmpObj.montantdecouvert === "") {
                        tmpObj.montantdecouvert = 0.00;
                    }
        
                    createAccount(token, url, tmpObj, history).then((result) => {
                        if (result.resultat === "ok") {
                          setMessage({
                            text: parse(i18n.t("AccountCreation:success")),
                            type: "success"
                          });
                
                          setTimeout(function() {
                            history.push({
                                pathname:"/account/" + result.data.id
                            });
                          }, 2000);
                        }
                
                        if (result.resultat === "erreur") {
                          setMessage({
                            text: parse(i18n.t("AccountCreation:error")),
                            type: "error"
                          });
                        }
                
                        setCreationLaunched(false);
                    }, (error) => {
                        setMessage({
                          text: error,
                          type: "error"
                        });
                        setCreationLaunched(false);
                    });
                }
            } else {
                setCreationLaunched(false);
            }
        }
        
    };

    const cancelForm = event => {
        event.preventDefault();
        setCreationLaunched(false);
        history.goBack();
    };

    useEffect(() => {
        setCreateAccount(true);

        return () => setCreateAccount(false);
    }, [setCreateAccount])

    return (
        <>
        {uuid !== null &&
            <>
            <HelmetProvider>
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{parse(t('AccountCreation:title'))}</title>
                    <meta name="description" content={parse(t("AccountCreation:desc"))} />
                </Helmet>
            </HelmetProvider>
            <div className="account-creation">
                <header className='header bg-turquoise box-shadow flex flex-direction-row justify-content-between align-items-center'>
                    <div className='input cl-white uppercase bolder'>
                        {parse(t('AccountCreation:card-id'))}
                        <div className='uuid bg-white cl-black text-transform-none normal'>{uuid}</div>
                    </div>
                    <img src={bgUuid} alt={t("NDEFReaderModal:set-card")} width="379" height="188" className=''/>
                </header>

                <div className='search flex flex-direction-row align-items-center justify-content-between relative bg-grey-light box-shadow'>
                    <div className='title uppercase bolder-x'>{parse(t('AccountCreation:search-in-db'))}</div>
                    <input type="text" value={filterClientsCI} placeholder={parse(t('AccountCreation:active-account-name'))} className='form-control text-search' onChange={(e)=>{
                        
                        if (!clientsList) {
                            setClientsList(true);
                        }

                        if (e.target.value === "") {
                            setHide(true);
                        } else {
                            setHide(false);
                        }

                        
                        setFilterClients(e.target.value.toLowerCase());
                        setFilterClientsCI(e.target.value);
                        
                    }} />
                    
                    <div className="label-styled flex flex-direction-row align-items-center justify-content-center relative">
                        <input type="checkbox" id="on-site" name="on-site" checked={present} onChange={(e)=>{
                        setPresent(!present);
                    }} />
                        <label className="fake-label bg-grey-dark flex flex-direction-row align-items-center justify-content-center relative" htmlFor="on-site"></label>{parse(t('AccountCreation:on-site'))}
                    </div>

                    {clientsList &&
                        <ClientList 
                            clientsList={clientsList} 
                            setClientsList={setClientsList}
                            filterClients={filterClients} 
                            setFilterClients={setFilterClients}
                            fields={fields} 
                            setFields={setFields}
                            hide={hide} 
                            present={present} 
                            setHide={setHide} 
                            setError={setError}
                            uuid={uuid}
                            {...props} 
                        />
                    }
                    
                    
                </div>

                <h2 className='uppercase bolder-x'>{parse(t('AccountCreation:manual-input'))}</h2>

                <form className='form flex flex-direction-row align-items-start justify-content-between relative' action='' method='post' onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className='left relative'>
                        <label htmlFor='nom'><span className='title'>{parse(t('AccountCreation:nom'))} <sup className='cl-error1'>*</sup></span> <span className='cl-error1 bolder-x'>{error.nom ? errors.nom : ""}</span><br/>
                            <input type="text" value={fields.nom || ""} className='form-control nom' name="nom" id='nom' onChange={handleOnChange} />
                        </label>
                        <label htmlFor='email'><span className='title'>{parse(t('AccountCreation:email'))} <sup className='cl-error1'>*</sup></span> <span className='cl-error1 bolder-x'>{error.email ? errors.email : ""}{error.emailWrong ? errors.emailWrong : ""}</span><br/>
                            <input type="email" value={fields.email || ""} className='form-control email' id='email' name="email" onChange={handleOnChange} />
                        </label>
                        <label htmlFor='hebergement'><span className='title'>{parse(t('AccountCreation:hebergement'))}</span><br/>
                            <input type="text" value={fields.hebergement || ""} className='form-control hebergement' id='hebergement' name="hebergement" onChange={handleOnChange} />
                        </label>
                        <label htmlFor='tel'><span className='title'>{parse(t('AccountCreation:tel'))}</span><br/>
                            <input type="text" value={fields.tel || ""} className='form-control tel' id='tel' name="tel" onChange={handleOnChange} />
                        </label>
                    </div>

                    <div className='right relative'>
                        <label htmlFor='prenom'><span className='title'>{parse(t('AccountCreation:prenom'))} <sup className='cl-error1'>*</sup></span> <span className='cl-error1 bolder-x'>{error.prenom ? errors.prenom : ""}</span><br/>
                            <input type="text" value={fields.prenom || ""} className='form-control prenom' id='prenom' name="prenom" onChange={handleOnChange} />
                        </label>
                        <label htmlFor='datefin'><span className='title'>{parse(t('AccountCreation:datefin'))}</span><br/>
                            <input type="date" value={fields.datefin.replace(/(\d+)(\d{2})(\d{2})/g, '$1-$2-$3') || ""} className='form-control datefin' id='datefin' name="datefin" onChange={handleOnChange} />
                        </label>
                        <label htmlFor='montantdecouvert'><span className='title uppercase'>{parse(t('AccountCreation:montantdecouvert', {devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "EUR"}))}</span><br/>
                            <input type="number" value={fields.montantdecouvert || ''} className='form-control montantdecouvert' name="montantdecouvert" id='montantdecouvert' onChange={handleOnChange} />
                        </label>
                        <button type="submit" className='submit bg-yellow uppercase bolder-x' id='submit' onClick={submitForm}>{parse(t('AccountCreation:submit'))}</button>
                        <button className='cancel bg-black cl-white uppercase bolder-x' id='cancel' onClick={cancelForm}>{parse(t('AccountCreation:cancel'))}</button>
                    </div>
                </form>

                {message !== null && 
                    <div className={`message-popup${message?.text !== null ? " on" : ""}${message?.type !== "success" ? " cl-error1" : ""} full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}><div className="message-container relative bg-white box-shadow"><button className="close absolute" onClick={(e) => {
                        setMessage(null);
                    }}></button><div className="message">{message?.text}</div></div></div>
                }
            </div>
            </>
        }
        </>
    )
}

export default withTranslation()(AccountCreation)