
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { unblockCard, blockCard, splitCards, replaceDot } from "../Helpers";
import '../../assets/css/components/Account/ManageCards.css';

const ManageCards = (props) => {
    const {i18n, t} = useTranslation();
    const {data, setData, setReadCardModal, replace, replaceAnnexe, setReplace, setReplaceAnnexe, setUUIDAssocie, setIDAssocie, setMessage } = props;
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const canUnblock = localStorage.getItem("r-fctdebloquer") === 'true';
    const [working, setWorking] = useState(null);
    const [activeCards, setActiveCards] = useState(null);
    const [replacedCards, setReplacedCards] = useState(null);

    useEffect(()=> {
        if (data !== null) {
            let splittedCards = splitCards(data);
            setActiveCards(splittedCards[0]);
            setReplacedCards(splittedCards[1]);
        }
    },[data]);

    return(
        <>
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <title>{parse(t('ManageCards:title'))}</title>
                <meta name="description" content={parse(t("ManageCards:desc"))} />
            </Helmet>
        </HelmetProvider>
        {data !== null &&
            <div className="manage-cards">


                <div className='header'>
                    <div className='panel-title blocage'>{parse(t("ManageCards:manage-cards"))}</div>
                    <div className='panel-subtitle bolder'>{parse(t("ManageCards:select-card"))}</div>
                </div> 

                <div className='cards-container relative '>
                    <div className=" relative title bolder-x">{parse(t("ManageCards:main-card"))}</div>
                    
                    <div className='cards relative'>
                        <div className={`card relative main flex flex-direction-row align-items-center justify-content-between box-shadow${data.bloqueetat ? " blocked" : ""}`}>
                            <div className='name'>{data.nom}</div>
                            {!data.bloqueetat &&
                                <div className='limit'>{parse(t("ManageCards:no-limit"))}</div>
                            }

                            {data.bloqueetat &&
                                <div className='limit bolder-x'>{parse(t("ManageCards:blocked-card"))}</div>
                            }
                            
                            <button className='replace uppercase bolder' onClick={(e) =>{
                                if (!replace) {
                                    setReplace(true);
                                    setReadCardModal(true);
                                }
                            }}>{parse(t("ManageCards:replace"))}</button>
                        </div>
                    </div>

                    {activeCards !== null && activeCards.length > 0 &&
                    <div className="title bolder-x">{parse(t("ManageCards:associated-cards"))}</div>
                    }
                    <div className='cards relative'>
                        {activeCards !== null && Object(activeCards).map((obj, i) => {
                            return (
                                <div key={i} className={`card relative associate flex flex-direction-row align-items-center justify-content-between box-shadow${(activeCards[i].bloqueetat)  ? " blocked" : ""}${working !== null ? (working[i] ? " working" : "") : ""}`}>

                                    <div className='name'>{obj.textelogiciel}</div>

                                    {obj.limitemontant === 0 && obj.limitetype === "aucune" && !obj.bloqueetat &&
                                        <div className='limit normal'>{parse(t("ManageCards:no-limit"))}</div>
                                    }

                                    {obj.limitemontant > 0 && obj.limitetype !== "aucune" && !obj.bloqueetat &&
                                        <div className='limit normal'>
                                            {parse(t("ManageCards:limit", {number:replaceDot(obj.limitemontant), type:parse(t("ManageCards:" + obj.limitetype)), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}<br/>
                                            {Number(obj.disponible) >= 0 &&
                                            <i>{parse(t("ManageCards:available", {number:replaceDot(obj.disponible), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</i>
                                            }
                                        </div>
                                    }

                                    {obj.bloqueetat && 
                                        <button className='replace uppercase bolder' onClick={(e) =>{

                                            if (!replaceAnnexe) {
                                                setReplaceAnnexe(true);
                                                setIDAssocie(obj.id);
                                                setUUIDAssocie(obj.bracelet);
                                                setReadCardModal(true);
                                            }
                                            
                                        }}>{parse(t("ManageCards:replace"))}</button>
                                    }

                                        <div className='blockstate flex flex-direction-row justify-content-between align-items-center'>
                                        <div className='block-text'>{parse(t("ManageCards:blocked"))}</div>
                                        <div className={`toggle`}>
                                            <label className="switch" htmlFor={`bloqueetat-${i}`}>
                                                <input disabled={!canUnblock && activeCards[i].bloqueetat ? true : false} type="checkbox" name={`bloqueetat-${i}`} id={`bloqueetat-${i}`} checked={!activeCards[i].bloqueetat ? true : false} value={activeCards[i].bloqueetat} onChange={(e)=> {

                                                    if (!working) {
                                                        setWorking(prevState =>({
                                                            ...prevState,
                                                            [i] : true
                                                        }));

                                                        let tmpObj = {
                                                            token: token,
                                                            idprincipal: data.id,
                                                            uuidprincipal: data.braceletprincipal,
                                                            idannexe:obj.id,
                                                            uuidannexe:obj.bracelet,
                                                            motif: ""
                                                        };
                                                        
                                                        if(!obj.bloqueetat) {

                                                            blockCard(url, tmpObj).then((result) => {
                                                                if (result.resultat === "ok") {
                                                                    let tmpData = result.data;
                                                                    tmpData.mouvements = data.mouvements;
                                                                    setData(tmpData);
                                                                    let splittedCards = splitCards(tmpData);
                                                                    setActiveCards(splittedCards[0]);
                                                                    setReplacedCards(splittedCards[1]);
                                                                }

                                                                if (result.resultat === "erreur") {

                                                                    if (result.reponse === "bracelet déjà bloqué") {
                                                                        setMessage(parse(t("ManageCards:account-already-blocked")));
                                                                    }

                                                                    if (result.reponse === "Pb TOKEN") {
                                                                        setMessage(parse(t("ManageCards:token-issue")));
                                                                    }
                                                                    
                                                                    if (result.reponse === "Données manquantes") {
                                                                        setMessage(parse(t("ManageCards:missing-data")));
                                                                    }

                                                                    if (result.reponse === "Bracelet non trouvé, données incohérentes") {
                                                                        setMessage(parse(t("ManageCards:wrong-data")));
                                                                    }
                                                                }
                                                                
                                                                setWorking(false);
                                                            },
                                                            (error) => {
                                                                setMessage(parse(error));
                                                                setWorking(false);
                                                            });
                                                        } else {
                                                            
                                                            unblockCard(url, tmpObj).then((result) => {
                                                                if (result.resultat === "ok") {
                                                                    let tmpData = result.data;
                                                                    tmpData.mouvements = data.mouvements;
                                                                    let splittedCards = splitCards(tmpData);
                                                                    setData(tmpData);
                                                                    setActiveCards(splittedCards[0]);
                                                                    setReplacedCards(splittedCards[1]);
                                                                }

                                                                if (result.resultat === "erreur") {

                                                                    if (result.reponse === "bracelet non bloqué") {
                                                                        setMessage(parse(t("ManageCards:account-not-blocked")));
                                                                    }

                                                                    if (result.reponse === "Pb TOKEN") {
                                                                        setMessage(parse(t("ManageCards:token-issue")));
                                                                    }
                                                                    
                                                                    if (result.reponse === "Données manquantes") {
                                                                        setMessage(parse(t("ManageCards:missing-data")));
                                                                    }

                                                                    if (result.reponse === "Bracelet non trouvé, données incohérentes") {
                                                                        setMessage(parse(t("ManageCards:wrong-data")));
                                                                    }
                                                                }
                                                                
                                                                setWorking(false);
                                                            },
                                                            (error) => {
                                                                setMessage(parse(error));
                                                                setWorking(false);
                                                            });
                                                        }
                                                    }
                                                    
                                                }}/>
                                                <div></div>
                                            </label>
                                        </div>
                                        <div className='active-text'>{parse(t("ManageCards:active"))}</div>

                                    </div>
                                </div>
                            );
                        })}
                    </div>


                    {replacedCards !== null && replacedCards.length > 0 &&
                    <div className="title bolder-x">{parse(t("ManageCards:replaced-cards"))}</div>
                    }
                    <div className='cards relative'>
                        {replacedCards !== null && Object(replacedCards).map((obj, i) => {
                           
                            return (
                                <div key={i} className={`card relative associate flex flex-direction-row align-items-center justify-content-between box-shadow blocked${working !== null ? (working[i] ? " working" : "") : ""}`}>
                                    <div className='name'>{obj.textelogiciel}</div>
                                    <div className='blockstate bolder-x flex flex-direction-row justify-content-between align-items-center'>{parse(t("ManageCards:replaced-card"))}</div>
                                </div>
                            );
                        })}
                    </div>
                    

                </div>
                
            </div>
        }
        </>
    );

      
}

export default ManageCards;