import React, { useState, useEffect } from 'react';
import { useParams, Route, Switch } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { getSingleAccount, testCurrentToken } from "../Helpers";
import UserMenu from '../UserMenu'
import Menu from './Menu'
import LanguageSwitcher from '../LanguageSwitcher'
import AccountInfo from './AccountInfo'
import CardList from './CardList'
import Operations from './Operations'
import ContestationModal from './Modals/ContestationModal'
import EditCardModal from './Modals/EditCardModal'
import ReadCardModal from './Modals/ReadCardModal'
import AddCardModal from './Modals/AddCardModal'
import ManageCards from './ManageCards'
import Reload from './Reload'
import Close from './Close'
import Infos from './Infos'
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import loader from '../../assets/images/loader.svg';
import '../../assets/css/components/Account/Account.css';

const Account = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { history, location } = props;
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const logo = localStorage.getItem("r-logo");
    const [data, setData] = useState(null);
    const [contestationModal, setContestationModal] = useState({state:false,obj:null, contested:null});
    const [editCardModal, setEditCardModal] = useState(false);
    const [readCardModal, setReadCardModal] = useState(false);
    const [addCardModal, setAddCardModal] = useState(false);
    const [loaderState, setLoaderState] = useState("");
    const [uuid, setUUID] = useState("");
    const [idassocie, setIDAssocie] = useState("");
    const [uuidassocie, setUUIDAssocie] = useState("");
    const [replace, setReplace] = useState(false);
    const [replaceAnnexe, setReplaceAnnexe] = useState(false);
    const [message, setMessage] = useState("");
    const [contestableOps, setContestableOps] = useState(null);

    useEffect(()=>{

        let isActive = true;

        if (data === null) {

            if (token !== null && url !== null) {

                testCurrentToken(token, url, history);
            
                getSingleAccount(token, id, url).then((result) => {
                    
                    if (result !== null && result.data?.cloturele !== "") {
                        if (isActive) {
                            setData(result);
                            if (loaderState === "") {
                                setLoaderState("off hidden");
                            }

                            localStorage.removeItem('r-submitTimeLimit')
                            localStorage.removeItem('r-trialLimit');
                        }
                    } else {
                        history.push({
                            pathname:"/dashboard"
                        });
                    }
                });

                
            }
        } else {
            if (data?.cloturele !== "") {
                history.push({
                    pathname:"/dashboard"
                });
            }
        }

        return () => {
            isActive = false;
        }

      }, [history, token, url, id, loaderState, data]);

    return (
        <>
        <div className={`loader ${loaderState}`}>
            <img src={loader} alt={t("Account:loading")} width="100" height="100" />
        </div>
        {data !== null &&
            <>
            <div className="cols">
                <div className="col-1 flex flex-direction-column justify-content-between align-items-center relative">
                    <Logo className='logo' />
                    <div className="app-title bolder-x">{parse(t('Account:app-title'))}</div>

                    <div className='account-info relative flex flex-direction-column'>
                        <button className='back cl-turquoise' onClick={(e) => {
                            history.push({
                                pathname: "/dashboard"
                            });
                        }}>{parse(t("Account:back"))}</button>
                        <div className="account-summary text-center full-width relative">
                            {logo !== null &&
                                <img src={logo} alt="" width="auto" height="auto" className="estab-logo box-shadow" />
                            }
                            <div className='estab-name bolder-x relative'>{localStorage.getItem("r-etablissement")}</div>
                        </div>
                    </div>

                    <Menu data={data} {...props} />
                
                </div>

                <div className="col-2">
                    <AccountInfo data={data} {...props} />
                    <CardList data={data} setReadCardModal={setReadCardModal} {...props} />
                </div>

                <div className="col-3 relative">
                    <UserMenu {...props} />
                    <LanguageSwitcher className="absolute" />
                    
                    <Operations data={data} setEditCardModal={setEditCardModal} setContestationModal={setContestationModal} contestationModal={contestationModal} contestableOps={contestableOps} setContestableOps={setContestableOps} {...props} />
                    
                    <Switch location={location}>
                        <Route exact path={`/account/:id/manage-cards`}>
                            <ManageCards 
                                replace={replace}
                                replaceAnnexe={replaceAnnexe}
                                setReplace={setReplace}
                                setReplaceAnnexe={setReplaceAnnexe}
                                data={data} 
                                setData={setData}
                                setIDAssocie={setIDAssocie}
                                setUUIDAssocie={setUUIDAssocie}
                                setReadCardModal={setReadCardModal}
                                setMessage={setMessage}
                                {...props} />
                        </Route>
                    </Switch>

                    <Switch location={location}>
                        <Route exact path={`/account/:id/reload`}>
                            <Reload 
                                data={data} 
                                setData={setData}
                                
                                {...props} />
                        </Route>
                    </Switch>

                    <Switch location={location}>
                        <Route exact path={`/account/:id/close`}>
                            <Close 
                                data={data} 
                                setData={setData}
                                
                                {...props} />
                        </Route>
                    </Switch>

                    <Switch location={location}>
                        <Route exact path={`/account/:id/info`}>
                            <Infos 
                                data={data} 
                                setData={setData}
                                
                                {...props} />
                        </Route>
                    </Switch>

                </div>
            </div>

            {message !== "" &&
            <div className={`message-popup${message !== "" ? " on" : ""} full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}><div className="message-container relative bg-white box-shadow"><button className="close absolute" onClick={(e) => {
                    setMessage("");
            }}></button><div className="message">{message}</div></div></div>
            }

            {contestationModal.state &&
                <ContestationModal
                    setContestationModal={setContestationModal}
                    setData={setData}
                    data={data}
                    contestationModal={contestationModal}
                    contestableOps={contestableOps}
                    setContestableOps={setContestableOps}
                    {...props}
                />
            }

            {editCardModal &&
                <EditCardModal
                    setEditCardModal={setEditCardModal}
                    setReadCardModal={setReadCardModal}
                    data={data}
                    setData={setData}
                    {...props}
                />
            }

            {readCardModal && data !== null &&
                <ReadCardModal
                    data={data}
                    addCardModal={addCardModal}
                    readCardModal={readCardModal}
                    uuid={uuid}
                    idassocie={idassocie}
                    uuidassocie={uuidassocie}
                    setData={setData}
                    setReadCardModal={setReadCardModal}
                    setAddCardModal={setAddCardModal}
                    setReplace={setReplace}
                    setReplaceAnnexe={setReplaceAnnexe}
                    replace={replace}
                    replaceAnnexe={replaceAnnexe}
                    setUUID={setUUID}
                    setMessage={setMessage}
                    {...props}
                />
            }

            {addCardModal &&
                <AddCardModal
                    data={data}
                    setData={setData}
                    setReadCardModal={setReadCardModal}
                    setAddCardModal={setAddCardModal}
                    uuid={uuid}
                    {...props}
                />
            }
            </>
        }
       
        </>
    )
}

export default withTranslation()(Account);