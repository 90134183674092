import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { formatDate, replaceDot } from "../Helpers";
import '../../assets/css/components/Account/Infos.css';

const Infos = (props) => {
    const {i18n, t} = useTranslation();
    const {data} = props;

    return(
        <>
            <HelmetProvider>
                <Helmet htmlAttributes={{ lang : i18n.language }}>
                    <title>{parse(t('Infos:title'))}</title>
                    <meta name="description" content={parse(t("Infos:desc"))} />
                </Helmet>
            </HelmetProvider>
            <div className="infos">
                <div className='panel-title infos'>{parse(t("Infos:panel-title"))}</div>

                <div className='infos-container relative'>
                    <div className='title cl-turquoise bolder-x'>{parse(t("Infos:panel-title"))}</div>

                    <div className="data flex flex-direction-row align-items-start justify-content-between">
                        <div className='part-1'>
                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:name"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{data?.nom}</div>
                            </div>
                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:email"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{data?.email}</div>
                            </div>
                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:stay"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{data?.hebergement}</div>
                            </div>

                        </div>
                        <div className='part-2'>
                        
                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:tel"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{data?.tel}</div>
                            </div>

                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:dep-date"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{formatDate(data?.datedepart)}</div>
                            </div>

                            <div className='field'>
                                <div className='label cl-grey-light2'>{parse(t("Infos:negative-balance"))}</div>
                                <div className='info flex flex-direction-column justify-content-center bg-grey-light3'>{data?.montantdecouvert} {data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}</div>
                            </div>

                        </div>
                    </div>
                    <div className='title cl-turquoise bolder-x'>{parse(t("Infos:cards-info"))}</div>

                    <div className='list table full-width'>
                        <div className='table-heading'>
                            <div className='table-row cl-grey-dark bold'>
                                <div className='row-1'>{parse(t("Infos:card-type"))}</div>
                                <div className='row-2'>{parse(t("Infos:osmopay-name"))}</div>
                                <div className='row-3'>{parse(t("Infos:webname"))}</div>
                                <div className='row-4'>{parse(t("Infos:limit-info"))}</div>
                                <div className='row-5'>{parse(t("Infos:uuid"))}</div>
                            </div>
                        </div>

                        <div className='table-body'>
                        <div className='table-row item'>
                            <div className='row-1'>{parse(t("Infos:main-card"))}</div>
                            <div className='row-2'>{parse(data?.nom)}</div>
                            <div className='row-3'>{parse(data?.texteutilisateur)}</div>
                            <div className='row-4'>{parse(t("Infos:no-limit"))}</div>
                            <div className='row-5'>{data?.braceletprincipal}</div>
                        </div>
                        {data !== null && 
                        <>
                            {data.braceletannexes.length > 0 && Object(data.braceletannexes).map((obj, i) => {
                                return (
                                    <div key={i} className='table-row item'>
                                        <div className='row-1'>{parse(t("Infos:associate-card"))}</div>
                                        <div className='row-2'>{parse(obj?.textelogiciel)}</div>
                                        <div className='row-3'>{parse(obj?.texteutilisateur)}</div>
                                        <div className='row-4'>
                                        {obj.limitemontant === 0 && obj.limitetype === "aucune" && !obj.bloqueetat &&
                                            <div className='limit normal'>{parse(t("Infos:no-limit"))}</div>
                                        }

                                        {obj.limitemontant > 0 && obj.limitetype !== "aucune" && !obj.bloqueetat &&
                                            <div className='limit normal'>
                                                {parse(t("Infos:limit", {number:replaceDot(obj.limitemontant), type:parse(t("Infos:" + obj.limitetype)),devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}<br/>
                                                {Number(obj.disponible) >= 0 &&
                                                <i>{parse(t("Infos:available", {number:replaceDot(obj.disponible),devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</i>
                                                }
                                            </div>
                                        }

                                        {obj.bloqueetat && !obj.remplaced &&
                                            <div className='limit normal'>
                                                {parse(t("Infos:card-blocked"))}
                                            </div>
                                        }

                                        {obj.remplaced &&
                                            <div className='limit normal'>
                                                {parse(t("Infos:card-replaced"))}
                                            </div>
                                        }
                                        </div>
                                        <div className='row-5'>{obj?.bracelet}</div>
                                    </div>
                                )
                            })}
                        </>

                        }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Infos;