import React from 'react';
import parse from 'html-react-parser';
import { withTranslation, useTranslation } from 'react-i18next';
import '../../assets/css/components/Dashboard/NDEFButton.css';

const NDEFButton = (props) => {
    const {t} = useTranslation();
    const { createAccount, setModal } = props;

    return (
        <button disabled={createAccount} className={`ndef-button bg-yellow box-shadow full-width relative ${createAccount ? " detected" : ""}`} onClick={()=>{
            setModal(true);
        }}>
            <div className="btn-txt cl-black uppercase bolder-x text-center">{
                createAccount ? 
                    parse(t('NDEFButton:new-card')) :
                    parse(t('NDEFButton:read-bracelet'))
            }</div>
        </button>
    )
}

export default withTranslation()(NDEFButton);