import { Switch, Route, useParams, NavLink } from "react-router-dom";
import parse from "html-react-parser"
import { useTranslation } from "react-i18next";
import '../../assets/css/components/Account/Menu.css';

const Menu = (props) => {
    const { id, cid } = useParams()
    const { t } = useTranslation()
    const { location, readCard, data } = props;


    return(
        <div className={`menu`}>
            <div className="menu-item item1">

            <Switch location={location}>

                <Route path={`/account/${id}/manage-cards`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

                <Route path={`/account/${id}/reload`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                <Route path={`/account/${id}/close`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                <Route path={`/account/${id}/info`}>
                    <NavLink exact onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`}><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

                <Route exact path={`/account/${id}/${cid}`}>
                    <NavLink onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`} activeClassName="active"><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>
                
                <Route exact path={`/account/${id}`}>
                    <NavLink onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}`;
                        }
                    }} className={`bg-white cl-black text-center mulish-font bold`} to={`/account/${id}`} activeClassName="active"><i>&nbsp;</i><span>{parse(t("Menu:menu1"))}</span></NavLink>
                </Route>

            </Switch>

            </div>
            <div className="menu-item item2">
                <NavLink onClick={e => {
                    if (data?.cloturele !== undefined && data?.cloturele !== '') {
                        e.preventDefault()
                    } else {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}/manage-cards`;
                        }
                    }
                }} activeClassName="active" className={`${data?.cloturele !== undefined && data?.cloturele !== '' ? "disabled" : ""}`} to={`/account/${id}/manage-cards`}><i>&nbsp;</i><span>{parse(t("Menu:menu2"))}</span></NavLink>
            </div>
            <div className="menu-item item3">
                <NavLink onClick={(e) => {
                    if (Number(data?.avecrechargement) === 0 || Number(data?.blocageetat) === 1 || (data?.cloturele !== undefined && data?.cloturele !== "")) {
                        e.preventDefault()
                    } else {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}/reload`;
                        }
                    }
                }} activeClassName="active" className={`${(Number(data?.avecrechargement) === 0 || Number(data?.blocageetat) === 1 || (data?.cloturele !== undefined && data?.cloturele !== "")) ? "disabled" : ""}`} to={`/account/${id}/reload`}><i>&nbsp;</i><span>{parse(t("Menu:menu3"))}</span></NavLink>
            </div>
            <div className="menu-item item4">
                <NavLink activeClassName="active" to={`/account/${id}/close`} onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}/close`;
                        }
                    }}><i>&nbsp;</i><span>{parse(t("Menu:menu4"))}</span></NavLink>
            </div>
            <div className="menu-item item5">
                <NavLink activeClassName="active" className="" to={`/account/${id}/info`} onClick={(e) => {
                        if (readCard) {
                            e.preventDefault();
                            window.location.href = `/account/${id}/info`;
                        }
                    }}><i>&nbsp;</i><span>{parse(t("Menu:menu5"))}</span></NavLink>
            </div>
        </div>
    )
}

export default Menu