import React, { useState, useEffect } from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Route, Switch } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { clearLocalStorage, todayDate, getAllAccounts, testCurrentToken, replaceDot } from "../Helpers";
import UserMenu from '../UserMenu'
import LanguageSwitcher from '../LanguageSwitcher'
import SearchBox from './SearchBox'
import AccountsList from './AccountsList'
import AccountCreation from './AccountCreation/AccountCreation'
import NDEFButton from './NDEFButton'
import NDEFReaderModal from './Modals/NDEFReaderModal'
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import loader from '../../assets/images/loader.svg';
import '../../assets/css/components/Dashboard/Dashboard.css';

const Dashboard = (props) => {
    const { i18n, t } = useTranslation();
    const { history, location} = props;
    const token = localStorage.getItem("r-token");
    const url = localStorage.getItem("r-url");
    const logo = localStorage.getItem("r-logo");
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState('');
    const [createAccount, setCreateAccount] = useState(false);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState("");
    const [loaderState, setLoaderState] = useState("");
    
    useEffect(()=>{
        
      let isActive = true;

      if (token !== null) {
          testCurrentToken(token, url, history);
          getAllAccounts(token, url).then((result) => {
            if (result !== undefined) {
                if (isActive) {
                    localStorage.setItem('r-etablissement', result.etablissement)
                    setData(result);
                    setLoaderState("off hidden");
                }
            }
          });
      } else {
          clearLocalStorage();
          history.push({
              pathname:"/login"
          });
      }


      return () => { isActive = false };
      
    }, [history, token, url, location]);


    return (

      <div className="cols">

        <HelmetProvider>
          <Helmet htmlAttributes={{ lang : i18n.language }}>
          <title>{parse(t('Dashboard:title'))}</title>
          <meta name="description" content={parse(t("Dashboard:desc"))} />
          </Helmet>
        </HelmetProvider>
          
        <div className={`loader ${loaderState}`}>
            <img src={loader} alt={t("Dashboard:loading")} width="100" height="100" />
        </div>
        <div className="col-1 flex flex-direction-column justify-content-between align-items-center relative">
            <Logo className='logo' />
            <div className="app-title bolder-x">{parse(t('Dashboard:app-title'))}</div>

            {data !== null &&
            <div className="account-summary text-center full-width relative">
                {logo !== null &&
                    <img src={logo} alt="" width="auto" height="auto" className="estab-logo box-shadow" />
                }
                <div className='estab-name bolder-x relative'>{data.etablissement}</div>
                
                <Switch location={location}>
                    <Route path={`/dashboard`}>
                        <>
                            <div className='total-amount relative'>
                                <div className='string bolder-x'>{parse(t('Dashboard:total-amount'))}</div>
                                <div className='date cl-grey-darker'>{parse(t('Dashboard:total-amount-date', {date:todayDate()}))}</div>
                                <div className='amount bolder-x uppercase'>{parse(t('Dashboard:total-amount-number', {number:replaceDot(data.soldetotal), devise:data?.monnaie !== "" ? data?.monnaie.toUpperCase() : "€"}))}</div>
                            </div>
                    
                            {(data.nbcompte !== null || data.nbbracelets) !== null &&
                            <div className='specs relative flex flex-direction-row align-items-center justify-content-evenly flex-wrap'>
                                {data.nbcompte !== null &&
                                <div className='spec relative bg-white box-shadow'>
                                    <div className="string cl-turquoise bolder-x uppercase">{parse(t("Dashboard:active-accounts"))}</div>
                                    <div className="date cl-grey-darker">{parse(t('Dashboard:total-amount-date', {date:todayDate()}))}</div>
                                    <div className="number bolder-x">{data.nbcompte}</div>
                                </div>
                                }

                                {data.nbbracelets !== null &&
                                <div className='spec relative bg-white box-shadow'>
                                    <div className="string cl-turquoise bolder-x uppercase">{parse(t("Dashboard:active-bracelets"))}</div>
                                    <div className="date cl-grey-darker">{parse(t('Dashboard:total-amount-date', {date:todayDate()}))}</div>
                                    <div className="number bolder-x">{data.nbbracelets}</div>
                                </div>
                                }
                            </div>
                            }
                        </>
                    </Route>
                </Switch>

            </div>
            }

            <NDEFButton 
                modal={modal} 
                setModal={setModal}
                createAccount={createAccount}
            />
        </div>

        <div className="col-2 relative">

            <Switch location={location} >
                <Route exact path={`/dashboard`}>
                    <div className="header relative flex flex-direction-row align-items-center justify-content-start">
                        <div className="panel-title bracelet bolder-x">{parse(t("Dashboard:header-title"))}</div>
                    </div>
                </Route>
            </Switch>

            <Switch location={location} >
                <Route exact path={`/dashboard/create-account/:uuid`}>
                    <div className="header relative flex flex-direction-row align-items-center justify-content-start">
                        <div className="panel-title bracelet bolder-x">{parse(t("AccountCreation:header-title"))}</div>
                    </div>
                </Route>
            </Switch>

            <UserMenu data={data} {...props} />
            <LanguageSwitcher className="absolute" />
        
            {data !== null && !createAccount &&
            <Switch location={location} >
                <Route exact path={`/dashboard`}>
                    <SearchBox data={data} filter={filter} setFilter={setFilter} {...props} />
                    <AccountsList data={data} filter={filter} {...props} />
                </Route>
            </Switch>
            }

            <Switch location={location} >
                <Route exact path={`/dashboard/create-account/:uuid`}>
                    <AccountCreation 
                        setCreateAccount={setCreateAccount}
                        token={token}
                        data={data}
                        url={url}
                        {...props} 
                    />
                </Route>
            </Switch>
            
        </div>
        {modal &&
            <NDEFReaderModal 
                setModal={setModal}
                modal={modal}
                setMessage={setMessage}
                setCreateAccount={setCreateAccount}
                {...props}
        />
        }

        {message !== "" &&
        <div className={`message-popup${message !== "" ? " on" : ""} full-width full-height fixed flex flex-direction-column align-items-center justify-content-center`}><div className="message-container relative bg-white box-shadow"><button className="close absolute" onClick={(e) => {
                setMessage("");
        }}></button><div className="message">{message}</div></div></div>
        }
        
      
      </div>
      )
    }
  
  export default withTranslation()(Dashboard);
  